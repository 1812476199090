import * as React from "react";
const SVGComponent = (props) => (
  <svg
    id="visual"
    viewBox="0 0 900 500"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <path
      d="M0 76L37.5 81.8C75 87.7 150 99.3 225 118.5C300 137.7 375 164.3 450 160.2C525 156 600 121 675 101C750 81 825 76 862.5 73.5L900 71L900 0L862.5 0C825 0 750 0 675 0C600 0 525 0 450 0C375 0 300 0 225 0C150 0 75 0 37.5 0L0 0Z"
      fill="#FFFFFF"
    />
    <path
      d="M0 366L37.5 336.8C75 307.7 150 249.3 225 248.5C300 247.7 375 304.3 450 326C525 347.7 600 334.3 675 316.8C750 299.3 825 277.7 862.5 266.8L900 256L900 69L862.5 71.5C825 74 750 79 675 99C600 119 525 154 450 158.2C375 162.3 300 135.7 225 116.5C150 97.3 75 85.7 37.5 79.8L0 74Z"
      fill="#E8E8E8"
    />
    <path
      d="M0 501L37.5 501C75 501 150 501 225 501C300 501 375 501 450 501C525 501 600 501 675 501C750 501 825 501 862.5 501L900 501L900 254L862.5 264.8C825 275.7 750 297.3 675 314.8C600 332.3 525 345.7 450 324C375 302.3 300 245.7 225 246.5C150 247.3 75 305.7 37.5 334.8L0 364Z"
      fill="#FFFFFF"
    />
  </svg>
);
export default SVGComponent;
