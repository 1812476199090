import Hero from "./homeComponents/Hero";
import Checklist from "./homeComponents/Checklist";
import AboutUs from "./homeComponents/AboutUs";
//import Founders from "./homeComponents/Founders";
import Survey from "./homeComponents/Survey";
//import Newsletter from "./homeComponents/Newsletter";
import Timeline from "./homeComponents/Timeline";
import EarlyAccess from "./homeComponents/EarlyAccess";
import Bar from "./homeComponents/Bar";
// import ContactUs from "./homeComponents/ContactUs";
import Divider from "./homeComponents/Divider";
import Demo from "./homeComponents/Demo";
import Blog from "./homeComponents/Blog";

import { renderToStaticMarkup } from 'react-dom/server';
import SVGComponent1 from './homeComponents/SVGComponents/SVGComponent1';
import SVGComponent2 from './homeComponents/SVGComponents/SVGComponent2'
import SVGComponent3 from './homeComponents/SVGComponents/SVGComponent3'

const svgString1 = encodeURIComponent(renderToStaticMarkup(<SVGComponent1 />));
const svgString2 = encodeURIComponent(renderToStaticMarkup(<SVGComponent2 />))
const svgString3 = encodeURIComponent(renderToStaticMarkup(<SVGComponent3 />))


function Home(){
    return(
        <>
        <div className="bg-no-repeat bg-cover" style={{backgroundImage: `url("data:image/svg+xml,${svgString1}")`}}>
            <Hero />
            <Bar />
        </div>
        <div className="bg-no-repeat bg-cover" style={{backgroundImage: `url("data:image/svg+xml,${svgString2}")`}}>
            <Checklist />
            <Demo />
        </div>
        <div className="bg-no-repeat bg-cover" style={{backgroundImage: `url("data:image/svg+xml,${svgString3}")`}}>
            <Blog />
            <Survey />
            <EarlyAccess />
        </div>
        </>
    )
}

export default Home;