import * as React from "react";
const SVGComponent1 = (props) => (
    <svg
    id="visual"
    viewBox="0 0 900 450"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <path
      d="M0 78L21.5 82.5C43 87 86 96 128.8 94.5C171.7 93 214.3 81 257.2 78.7C300 76.3 343 83.7 385.8 88.2C428.7 92.7 471.3 94.3 514.2 91.3C557 88.3 600 80.7 642.8 82.8C685.7 85 728.3 97 771.2 103.8C814 110.7 857 112.3 878.5 113.2L900 114L900 0L878.5 0C857 0 814 0 771.2 0C728.3 0 685.7 0 642.8 0C600 0 557 0 514.2 0C471.3 0 428.7 0 385.8 0C343 0 300 0 257.2 0C214.3 0 171.7 0 128.8 0C86 0 43 0 21.5 0L0 0Z"
      fill="#1B1B1B"
    />
    <path
      d="M0 208L21.5 208C43 208 86 208 128.8 217.8C171.7 227.7 214.3 247.3 257.2 256.3C300 265.3 343 263.7 385.8 267.3C428.7 271 471.3 280 514.2 284.5C557 289 600 289 642.8 275.5C685.7 262 728.3 235 771.2 223.8C814 212.7 857 217.3 878.5 219.7L900 222L900 112L878.5 111.2C857 110.3 814 108.7 771.2 101.8C728.3 95 685.7 83 642.8 80.8C600 78.7 557 86.3 514.2 89.3C471.3 92.3 428.7 90.7 385.8 86.2C343 81.7 300 74.3 257.2 76.7C214.3 79 171.7 91 128.8 92.5C86 94 43 85 21.5 80.5L0 76Z"
      fill="#1E1E1E"
    />
    <path
      d="M0 370L21.5 372.3C43 374.7 86 379.3 128.8 379.3C171.7 379.3 214.3 374.7 257.2 371.7C300 368.7 343 367.3 385.8 372.7C428.7 378 471.3 390 514.2 396C557 402 600 402 642.8 396C685.7 390 728.3 378 771.2 374.2C814 370.3 857 374.7 878.5 376.8L900 379L900 220L878.5 217.7C857 215.3 814 210.7 771.2 221.8C728.3 233 685.7 260 642.8 273.5C600 287 557 287 514.2 282.5C471.3 278 428.7 269 385.8 265.3C343 261.7 300 263.3 257.2 254.3C214.3 245.3 171.7 225.7 128.8 215.8C86 206 43 206 21.5 206L0 206Z"
      fill="#242424"
    />
    <path
      d="M0 451L21.5 451C43 451 86 451 128.8 451C171.7 451 214.3 451 257.2 451C300 451 343 451 385.8 451C428.7 451 471.3 451 514.2 451C557 451 600 451 642.8 451C685.7 451 728.3 451 771.2 451C814 451 857 451 878.5 451L900 451L900 377L878.5 374.8C857 372.7 814 368.3 771.2 372.2C728.3 376 685.7 388 642.8 394C600 400 557 400 514.2 394C471.3 388 428.7 376 385.8 370.7C343 365.3 300 366.7 257.2 369.7C214.3 372.7 171.7 377.3 128.8 377.3C86 377.3 43 372.7 21.5 370.3L0 368Z"
      fill="#FFFFFF"
    />
  </svg>
);
export default SVGComponent1;
