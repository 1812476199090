import { useAuth0 } from '@auth0/auth0-react';

const LoginButton = () => {
    const { loginWithRedirect, isAuthenticated } = useAuth0();
    return (
      <>
        {!isAuthenticated && ( // returns sign out button if user is authenticated, else returns blank
          <button className="bg-primary-500 hover:bg-primary-700 text-white font-bold py-2 px-4 border border-primary-700 rounded" onClick={() => loginWithRedirect()}>Sign in</button>
        )}
      </>
    );
  };

export default LoginButton