import { Route, Routes } from "react-router-dom";

import Sidebar from "../Sidebar";
import AppLanding from "./AppLanding";
import CreateEnvolvement from "./CreateEnvolvement";
import Envolvement from "./Envolvement";
import ExplorePage from "./explorePage/ExplorePage";

export default function AppHub() {
  return (
    <>
      <Sidebar>
        <Routes>
          <Route index element={<AppLanding />} />
          <Route path="/create" element={<CreateEnvolvement />} />
          <Route path="/envolvement/:id" element={<Envolvement />} />
          <Route path="/explore" element={<ExplorePage />} />
        </Routes>
      </Sidebar>
    </>
  );
}
