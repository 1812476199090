import { Route, Routes } from "react-router-dom";

import Payment from "./Payment";
import PaymentInfoPage from "./PaymentInfoPage";

export default function ProfilePage() {
  return (
    <>
      <Routes>
        <Route index element={<PaymentInfoPage />} />
        <Route path="/:id" element={<Payment />} />
      </Routes>
    </>
  );
}
