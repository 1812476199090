import blog from "../blogComponents/blogContent";
import { Link } from "react-router-dom";

function Blog() {
  const max = blog.length - 1;
  console.log(max);
  return (
    <div className="p-12">
      <div className="text-black text-center font-bold text-3xl pb-6">Our blog</div>
      <div className="flex flex-wrap">
        <div className="w-full items-center md:w-2/3 p-2 text-left text-gray-200">
          <Link to={`/blog/${blog[max].id}`}>
            <figure className="max-w-full">
              <img
                className="h-40 md:h-full max-w-full rounded-lg mx-auto"
                src={blog[max].img}
                alt="image description"
              />
              <figcaption className="mt-2 text-sm text-center text-black">
                <div>{blog[max].title}</div>
                <div className="text-xs">{blog[max].description}</div>
                <div className="text-xs">
                  {blog[max].author} | {blog[max].date}
                </div>
              </figcaption>
            </figure>
          </Link>
        </div>
        <div className="w-full md:w-1/3 p-2 text-center text-gray-700">
          <div className="flex flex-row md:flex-wrap">
            <Link to={`/blog/${blog[max - 1].id}`}>
              <figure className="items-start max-w-full">
                <img
                  className="h-auto md:h-56 max-w-full rounded-lg"
                  src={blog[max - 1].img}
                  alt="image description"
                />
                <figcaption className="mt-2 text-sm text-center text-black">
                  <div>{blog[max - 1].title}</div>
                  <div className="text-xs">{blog[max - 1].description}</div>
                  <div className="text-xs">
                    {blog[max - 1].author} | {blog[max - 1].date}
                  </div>
                </figcaption>
              </figure>
            </Link>
            <Link to={`/blog/${blog[max - 2].id}`}>
              <figure className="items-start max-w-full">
                <img
                  className="h-auto md:h-56 max-w-full rounded-lg"
                  src={blog[max - 2].img}
                  alt="image description"
                />
                <figcaption className="mt-2 text-sm text-center text-black">
                  <div>{blog[max - 2].title}</div>
                  <div>{blog[max - 2].description}</div>
                  <div className="text-xs">
                    {blog[max - 2].author} | {blog[max - 2].date}
                  </div>
                </figcaption>
              </figure>
            </Link>
          </div>
        </div>
      </div>
      <div className="text-center px-8 py-3 font-semibold rounded-full bg-gray-400 text-gray-800">Read more on <Link to={`/blog/`}>the blog page</Link></div>
    </div>
  );
}

export default Blog;
