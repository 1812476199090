import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import LoginButton from "../miscComponents/LoginButton";

const Profile = () => {
  const { user, isAuthenticated } = useAuth0();
  console.log(user);
  return (
    <>
      {isAuthenticated && ( // returns sign out button if user is authenticated, else returns blank
        <>
          <p className="mt-6 text-xl leading-8 sm:text-center">
            Here's what <span className="bold">WE</span> know about{" "}
            <span className="bold">YOU</span>
          </p>

          <article className="sm:text-center object-center">
            {user?.picture && (
              <img
                className="mx-auto border-double border-black border-8 rounded-full w-50"
                src={user.picture}
                alt={user?.name}
              />
            )}
            <h2>{user?.name}</h2>
            <ul className="text-xl">
              {Object.keys(user).map((objKey, i) => (
                <li key={i}>
                  <span className="text-primary-500">{objKey}</span>:{" "}
                  {user[objKey]}{" "}
                </li>
              ))}
            </ul>
          </article>
        </>
      )}

      {!isAuthenticated && (
        <>
        <div className="text-center text-xl p-28">
          <p className="mt-6 text-xl leading-8 sm:text-center">
            No profile found... Would you like to Sign in?
          </p>
          </div>
          <p className="text-center rounded-lg px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm ring-1 ring-gray-900/10 hover:ring-gray-900/20">
          <LoginButton/>
          </p>
        </>
      )}
      <p className="mt-6 text-xl leading-8 sm:text-center">
        Click
        <span className="font-semibold text-primary-600">
          <Link to="/profile/search">
            <span className="inset-0" aria-hidden="true" /> Here{" "}
          </Link>
        </span>
        to search for a user
      </p>
    </>
  );
};

export default Profile;
