import { useParams, Link } from "react-router-dom";
import React, { Component } from "react";

const backend_domain = process.env.REACT_APP_BACKEND_DOMAIN;
const backend_port = process.env.REACT_APP_BACKEND_PORT;

function ProfileSearchResults() {
  // interface Result{
  //   created_at: string;
  //   email: string;
  //   email_verified: string;
  //   identities:[
  //     connection: string,
  //     provider: string,
  //     user_id: string,
  //     isSocial: boolean,
  //   ]
  //   name: string;
  //   nickname: string;
  //   picture: string;
  //   updated_at: string;
  //   user_id: string;
  //   last_login: string;
  //   last_ip: string;
  //   logins_count: string;
  // }

  const [APIResponse, setAPIResponse] = React.useState(null);
  const { id } = useParams();

  React.useEffect(() => {
    fetch(`${backend_domain}${backend_port}/auth0_user_search/${id}`)
      .then((res) => res.json())
      .then((res) => setAPIResponse(res));
  }, []);

  console.log(APIResponse);

  return (
    <>
      {SanitizeResponse(APIResponse)}
      {APIResponse && APIResponse.length < 1 && <UserNotFound />}
      {APIResponse &&
        APIResponse.map(function (i) {
          return ReturnUser(i);
        })}{" "}
      {/*dynamically generates users*/}
    </>
  );
}

function SanitizeResponse(response) {
  console.log("Santitize response,", response);
  response?.length && console.log(response.length);
}

function UserNotFound() {
  return (
    <section className="flex items-center h-full p-16">
      <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div className="max-w-md text-center">
          <h2 className="mb-8 font-extrabold text-9xl dark:text-gray-600">
            <span className="sr-only">Error</span>...
          </h2>
          <p className="text-2xl font-semibold md:text-3xl">
            Sorry, no user can be found.
          </p>
          <p className="mt-4 mb-8 dark:text-gray-400">
            Maybe try{" "}
            <Link to="/profile/search" className="underline text-primary-600">
              searching
            </Link>{" "}
            again or{" "}
            <Link to="/contact-us" className="underline text-primary-600">
              contacting
            </Link>{" "}
            us and reporting this error!
          </p>
          <Link to="/">
            <span className="px-8 py-3 font-semibold rounded dark:bg-primary-400 dark:text-gray-900">
              Back to homepage
            </span>
          </Link>
        </div>
      </div>
    </section>
  );
}

function ReturnUser(user) {
  return (
    <>
      <Link to={`/profile/${user?.identities[0].user_id}`}>
        <div className="max-w-md p-8 sm:flex sm:space-x-6 dark:bg-gray-900 dark:text-gray-100">
          <div className="flex-shrink-0 w-full mb-6 h-44 sm:h-32 sm:w-32 sm:mb-0">
            {user?.picture && (
              <img
                src={user.picture}
                alt={user?.name}
                className="object-cover object-center w-full h-full rounded dark:bg-gray-500"
              />
            )}
          </div>
          <div className="flex flex-col space-y-4">
            <div>
              <h2 className="text-2xl font-semibold">{user?.nickname}</h2>
              <span className="text-sm dark:text-gray-400">
                {user?.identities[0].user_id}
              </span>
            </div>
            <div className="space-y-1">
              <span className="flex items-center space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  aria-label="Email address"
                  className="w-4 h-4"
                >
                  <path
                    fill="currentColor"
                    d="M274.6,25.623a32.006,32.006,0,0,0-37.2,0L16,183.766V496H496V183.766ZM464,402.693,339.97,322.96,464,226.492ZM256,51.662,454.429,193.4,311.434,304.615,256,268.979l-55.434,35.636L57.571,193.4ZM48,226.492,172.03,322.96,48,402.693ZM464,464H48V440.735L256,307.021,464,440.735Z"
                  ></path>
                </svg>
                <span className="dark:text-gray-400">{user?.email}</span>
              </span>
              <span className="flex items-center space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  aria-label="Phonenumber"
                  className="w-4 h-4"
                >
                  <path
                    fill="currentColor"
                    d="M449.366,89.648l-.685-.428L362.088,46.559,268.625,171.176l43,57.337a88.529,88.529,0,0,1-83.115,83.114l-57.336-43L46.558,362.088l42.306,85.869.356.725.429.684a25.085,25.085,0,0,0,21.393,11.857h22.344A327.836,327.836,0,0,0,461.222,133.386V111.041A25.084,25.084,0,0,0,449.366,89.648Zm-20.144,43.738c0,163.125-132.712,295.837-295.836,295.837h-18.08L87,371.76l84.18-63.135,46.867,35.149h5.333a120.535,120.535,0,0,0,120.4-120.4v-5.333l-35.149-46.866L371.759,87l57.463,28.311Z"
                  ></path>
                </svg>
                <span className="dark:text-gray-400">{user?.updated_at}</span>
              </span>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}

export default ProfileSearchResults;
