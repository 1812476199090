import { useState, useEffect } from "react";
import FileUpload from "../FileUpload";
import { useAuth0 } from "@auth0/auth0-react";
import SVGComponent from "./SVGComponent";

const backend_domain = process.env.REACT_APP_BACKEND_DOMAIN;
const backend_port = process.env.REACT_APP_BACKEND_PORT;

interface SubmissionContent {
  description: boolean;
  picture: boolean;
  url: boolean;
}
interface selectedProps {
  isSelected: boolean;
  posterID: string;
  currentUserID: string;
  isActive: boolean;
  postID: string;
  existingPostJSON: string;
  SubmissionIDPair: string;
  submissionContent: SubmissionContent;
}

interface SubmissionInterface {
  username: string;
  pfp: string;
  picture?: string;
  description?: string;
  url?: string;
  progress: {
    inReview: boolean;
    approved: boolean;
    completed: boolean;
  };
}

interface YourComponentProps {
  array: SubmissionInterface[];
}

export default function Activity(props: selectedProps) {
  const [paidFor, setPaidFor] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const [reRenderFlag, setReRenderFlag] = useState(false);

  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    fetch(
      `${backend_domain}${backend_port}/get_user_image_by_id/${props.posterID}`
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((posterImg) => setPosterImage(posterImg))
      .catch((error) => console.error("Error fetching poster image:", error));

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  const [userSubmissionResponse, setUserSubmissionResponse] = useState("");
  const [initialUserSubmissionResponse, setInitialUserSubmissionResponse] =
    useState("");

  // gets user data for poster view of submission
  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        const response = await fetch(
          `${backend_domain}${backend_port}/user_submissions/pull-submissions/${props.postID}`,
          {
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const responseData = await response.json();
        setUserSubmissionResponse(responseData);
        setInitialUserSubmissionResponse(responseData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        const response = await fetch(
          `${backend_domain}${backend_port}/user_submissions/user-access-to-submission/${props.SubmissionIDPair}`,
          {
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const responseData = await response.json();
        setPaidFor(responseData.paidFor);
        setSubmissionDescription(responseData.UserSubmission.description);
        setSubmissionPicture(responseData.UserSubmission.picture);
        setSubmissionUrl(responseData.UserSubmission.url);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const [selectedOptions, setSelectedOptions] = useState<
    (string | undefined)[]
  >([]);

  const [posterImage, setPosterImage] = useState("");

  const [postActive, setPostActive] = useState(false);

  const isSelected = props.isSelected;
  // booleans
  const [picture, setPicture] = useState(false);
  const [description, setDescription] = useState(false);
  const [url, setURL] = useState(false);

  // content
  const [submissionPicture, setSubmissionPicture] = useState("");
  const [submissionDescription, setSubmissionDescription] = useState("");
  const [submissionUrl, setSubmissionUrl] = useState("");

  const [message, setMessage] = useState("");

  const [collapsedItems, setCollapsedItems] = useState<number[]>(
    Array.from({ length: 50 }, (_: any, index: number) => index)
  );

  const toggleCollapse = (index: number) => {
    setCollapsedItems((prev) =>
      prev.includes(index)
        ? prev.filter((item) => item !== index)
        : [...prev, index]
    );
  };

  var jsondata = {
    picture: picture,
    description: description,
    url: url,
  };

  var usersubmissionjsondata = {
    picture: submissionPicture,
    description: submissionDescription,
    url: submissionUrl,
  };

  const chooseImgUrl = (submissionPicture: string) => {
    setSubmissionPicture(submissionPicture);
  };

  let handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const accessToken = await getAccessTokenSilently();

      let res = await fetch(
        `${backend_domain}${backend_port}/user_submissions/open-submissions/${props.postID}`,
        {
          method: "POST",
          body: JSON.stringify(jsondata),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      let data = await res.json();

      if (res.status === 200) {
        setPicture(false);
      } else {
        setMessage("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };

  var SubmissionProgressJSONData = {
    SubmissionProgressArray: userSubmissionResponse,
  };

  let handleSubmissionProgressUpdate = async (e: any) => {
    e.preventDefault();
    try {
      const accessToken = await getAccessTokenSilently();

      let res = await fetch(
        `${backend_domain}${backend_port}/user_submissions/update-submission-status/${props.SubmissionIDPair}`,
        {
          method: "POST",
          body: JSON.stringify(SubmissionProgressJSONData),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      let data = await res.json();

      if (res.status === 200) {
        setPicture(false);
      } else {
        setMessage("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };

  let handleUserSubmissionSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const accessToken = await getAccessTokenSilently();

      let res = await fetch(
        `${backend_domain}${backend_port}/user_submissions/post-user-submission/${props.SubmissionIDPair}`,
        {
          method: "POST",
          body: JSON.stringify(usersubmissionjsondata),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      let data = await res.json();

      if (res.status === 200) {
        setMessage("Submission Uploaded Succesfully");
      } else {
        setMessage("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };

  // ! REMOVE SOON
  let handlePaymentOverride = async (e: any) => {
    e.preventDefault();
    try {
      const accessToken = await getAccessTokenSilently();

      let res = await fetch(
        `${backend_domain}${backend_port}/user_submissions/add-user-to-submissions/${props.SubmissionIDPair}`,
        {
          method: "POST",
          body: JSON.stringify(jsondata),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  if (isSelected) {
    if (!props.isActive) {
      // inactive, no posts yet
      if (props.posterID === props.currentUserID) {
        return (
          <>
            {!postActive && (
              <>
                <div className="text-black container mx-auto flex flex-col items-center justify-center p-3 space-y-8 md:p-10 md:px-24 xl:px-48">
                  <SVGComponent />
                  <h1 className="text-black font-bold text-3xl leading-none text-center">
                    Nothing here yet
                  </h1>
                  <h1 className="text-gray-500 text-xl leading-none text-center"></h1>
                  <button
                    // onClick={handleSubmit}
                    onClick={() => setPostActive(true)}
                    className="text-2xl bg-black md:px-32 px-20 py-2 text-white border"
                  >
                    Open Submissions
                  </button>
                </div>
              </>
            )}
            {postActive && (
              <>
                <section className="p-6 dark:bg-gray-800 dark:text-gray-50">
                  {/* not active, poster is the user, but post just turned active */}
                  <form onSubmit={handleSubmit}>
                    <fieldset className="mx-auto grid grid-cols-4 gap-6 p-6 rounded-md">
                      <div className="text-4xl text-bold col-span-full sm:col-span-2">
                        Submission Settings
                      </div>
                    </fieldset>

                    <fieldset className="mx-auto grid grid-cols-4 gap-6 px-6 pb-6 rounded-md shadow-sm dark:bg-gray-900">
                      <div className="col-span-full sm:col-span-full border rounded-lg border-gray-300">
                        <div className="flex justify-between">
                          <div className="py-2 px-2">
                            Allow Picture Submissions
                          </div>
                          <div>
                            <input
                              onChange={(e) => setPicture(e.target.checked)}
                              className="mr-2 mt-[0.8rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary-500 checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                            />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className="mx-auto grid grid-cols-4 gap-6 px-6 pb-6 rounded-md shadow-sm dark:bg-gray-900">
                      <div className="col-span-full sm:col-span-full border rounded-lg border-gray-300">
                        <div className="flex justify-between">
                          <div className="py-2 px-2">
                            Allow Text Submissions
                          </div>
                          <div>
                            <input
                              onChange={(e) => setDescription(e.target.checked)}
                              className="mr-2 mt-[0.8rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary-500 checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                            />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className="mx-auto grid grid-cols-4 gap-6 px-6 pb-6 rounded-md shadow-sm dark:bg-gray-900">
                      <div className="col-span-full sm:col-span-full border rounded-lg border-gray-300">
                        <div className="flex justify-between">
                          <div className="py-2 px-2">
                            Allow URL (Links) Submissions
                          </div>
                          <div>
                            <input
                              onChange={(e) => setURL(e.target.checked)}
                              className="mr-2 mt-[0.8rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary-500 checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                            />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <button
                      type="submit"
                      className="px-16 py-3 font-semibold rounded-xl bg-primary-300"
                    >
                      Publish
                    </button>
                  </form>
                  {message ? <p>{message}</p> : null}
                </section>
              </>
            )}
          </>
        );
      }

      if (props.posterID !== props.currentUserID) {
        return (
          <div className="text-black container mx-auto flex flex-col items-center justify-center p-3 space-y-8 md:p-10 md:px-24 xl:px-48">
            <SVGComponent />
            <h1 className="text-black font-bold text-3xl leading-none text-center">
              Submissions are currently not enabled.
            </h1>
          </div>
        );
      }
    }
    if (props.isActive) {
      if (props.posterID !== props.currentUserID) {
        if (paidFor === true) {
          return (
            <>
              {/* <div>thanks for paying bbg</div> */}
              <div className="text-xl text-center font-bold">
                Your submission
              </div>
              <form onSubmit={handleUserSubmissionSubmit}>
                <div className="px-6 grid grid-cols-6 gap-4 col-span-full lg:col-span-3 border rounded-xl border-gray-200">
                  {props.submissionContent.description && (
                    <div className="col-span-full sm:col-span-6">
                      <label className="text-sm">Description</label>
                      <input
                        value={submissionDescription}
                        onChange={(e: any) =>
                          setSubmissionDescription(e.target.value)
                        }
                        type="text"
                        placeholder="Title"
                        required
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                  )}
                  {props.submissionContent.url && (
                    <div className="col-span-full sm:col-span-6">
                      <label className="text-sm">Link</label>
                      <input
                        value={submissionUrl}
                        onChange={(e: any) => setSubmissionUrl(e.target.value)}
                        type="url"
                        placeholder="https://www.youtube.com/"
                        required
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                  )}
                  {props.submissionContent.picture && (
                    // <div className="col-span-full sm:col-span-6">
                    //   <label className="text-sm">Picture</label>
                    //   <input
                    //     value={submissionPicture}
                    //     onChange={(e: any) =>
                    //       setSubmissionPicture(e.target.value)
                    //     }
                    //     type="text"
                    //     placeholder="Title"
                    //     required
                    //     className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    //   />
                    // </div>
                    <>
                      <div className="col-span-full sm:col-span-6">
                        <label className="text-sm">Picture</label>
                        <img src={submissionPicture} className="h-64 w-64" />
                      </div>

                      <FileUpload chooseImgUrl={chooseImgUrl} />
                    </>
                  )}
                  <div className="col-span-full sm:col-span-3">
                    <div className="text-center">
                      <button
                        type="submit"
                        className={`px-16 py-3 font-semibold rounded-xl bg-primary-300`}
                      >
                        Publish
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className={`${
                    message
                      ? "text-white font-bold bg-primary-500 border rounded-xl text-xl py-4 text-center"
                      : "hidden "
                  } && `}
                >
                  {message ? <p>{message}</p> : null}
                </div>
              </form>
            </>
          );
        }
        if (paidFor === false) {
          return (
            <div>
              Looks like you haven't paid for this yet. Would you like to pay
              here?
              <button
                onClick={handlePaymentOverride}
                className="text-2xl bg-black md:px-32 px-20 py-2 text-white border"
              >
                OVERRIDE BUTTON, SHOULD NORMALLY BE STRIPE
              </button>
            </div>
          );
        }
        // post is active, poster is not current suer
        // check the user is valid

        // if valid then check if they have submission

        // does not have submission: make submission

        // does have submission: show them submission they made
      }
      if (props.posterID === props.currentUserID) {
        // post is active, poster is current user

        // fix array off of any ASAP

        if (
          Array.isArray(userSubmissionResponse) &&
          Array.isArray(initialUserSubmissionResponse)
        ) {
          var array = [...userSubmissionResponse[0].userSubmissions];

          const handleRadioChange = (index: number, option: string) => {
            if (array[index].progress) {
              if (option === "inReview") {
                array[index].progress.inReview = true;
                array[index].progress.approved = false;
                array[index].progress.completed = false;
              }
              if (option === "approved") {
                array[index].progress.inReview = false;
                array[index].progress.approved = true;
                array[index].progress.completed = false;
              }
              if (option === "completed") {
                array[index].progress.inReview = false;
                array[index].progress.approved = false;
                array[index].progress.completed = true;
              }
            }

            setReRenderFlag(!reRenderFlag);
          };

          return (
            <div>
              <div className="flex justify-between items-center">
                <div className="w-1/2">Current Submissions:</div>
                <div className="flex flex-row w-1/3">
                  <div className="w-1/3">In-Review</div>
                  <div className="w-1/3">Approved</div>
                  <div className="w-1/3">Completed</div>
                </div>
                <span></span>
              </div>
              {array.map((submission: SubmissionInterface, index: number) => (
                <div key={index} className="border p-2 mb-2">
                  <div className="cursor-pointer font-semibold flex justify-between items-center">
                    <div className="flex flex-row w-1/2">
                      <img src={submission.pfp} className="h-8 w-8" />
                      <div className="block px-2">{submission.username}</div>
                    </div>
                    <div className="pl-9 flex flex-row justify-between w-1/3">
                      <div className="w-1/4">
                        <input
                          type="radio"
                          name={`radio-${index}`}
                          className="radio"
                          checked={submission?.progress.inReview}
                          onChange={() => handleRadioChange(index, "inReview")}
                        />
                      </div>
                      <div className="w-1/4">
                        <input
                          type="radio"
                          name={`radio-${index}`}
                          className="radio"
                          checked={submission?.progress.approved}
                          onChange={() => handleRadioChange(index, "approved")}
                        />
                      </div>
                      <div className="w-1/4">
                        <input
                          type="radio"
                          name={`radio-${index}`}
                          className="radio"
                          checked={submission?.progress.completed}
                          onChange={() => handleRadioChange(index, "completed")}
                        />
                      </div>
                    </div>
                    <span
                      className="border border-black rounded-xl px-2"
                      onClick={() => toggleCollapse(index)}
                    >
                      {collapsedItems.includes(index) ? "+" : "-"}
                    </span>
                  </div>
                  <div
                    className={`overflow-hidden transition-max-height duration-300 ${
                      collapsedItems.includes(index) ? "max-h-0" : "max-h-full"
                    }`}
                  >
                    <div className="flex md:flex-row flex-col justify-evenly">
                      {submission.picture && (
                        <div className="border border-gray-300 rounded-xl md:w-1/3">
                          <span>Picture:</span>
                          <img src={submission.picture} alt="Submission" />
                        </div>
                      )}
                      {submission.description && (
                        <div className="border border-gray-300 rounded-xl md:w-1/3">
                          Text: {submission.description}
                        </div>
                      )}
                      {submission.url && (
                        <div className="border border-gray-300 rounded-xl md:w-1/3">
                          URL:{" "}
                          <a href={submission.url}>
                            <span className="underline">{submission.url}</span>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-span-full sm:col-span-6">
                <div
                  className="text-white font-bold bg-primary-500 border rounded-xl text-xl py-4 text-center"
                  onClick={handleSubmissionProgressUpdate}
                >
                  Save changes{" "}
                </div>
              </div>
            </div>
          );
        } else {
          return <div>Current Submissions: No data available.</div>;
        }
      }
    }
  }
}
