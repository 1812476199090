import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import blog from "./blogContent";

import { useParams } from "react-router-dom";

export default function BlogLoader() {
  const { id } = useParams();

  const markdown = blog[id].markdown;

  return (
    <>
      <main className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-envolvlyGray">
        <div className="flex justify-between px-4 mx-auto max-w-screen-xl ">
          <article className="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-invert text-gray-400">
            <header className="not-format">
              <address className="flex items-center mb-6 not-italic">
                <div className="inline-flex items-center mr-3 text-sm text-white">
                  <div>
                    <a
                      href="#"
                      rel="author"
                      className="text-xl font-bold text-white"
                    >
                      {blog[id].author}
                    </a>
                    <p className="text-base font-light text-gray-400">
                      {blog[id].role}
                    </p>
                    <p className="text-base font-light text-gray-400">
                      <time>{blog[id].date}</time>
                    </p>
                  </div>
                </div>
              </address>
            </header>
            <ReactMarkdown
              className="prose"
              children={markdown}
              remarkPlugins={[remarkGfm]}
              components={{
                // Map `h1` (`# heading`) to use `h2`s.
                // Rewrite `em`s (`*like so*`) to `i` with a red foreground color.
                h1: ({ node, ...props }) => (
                  <h1
                    className="text-3xl font-extrabold leading-tight lg:mb-6 lg:text-4xl text-white"
                    {...props}
                  />
                ),
                h3: ({ node, ...props }) => (
                  <h1
                    className="py-2 text-xl font-extrabold leading-tight text-white"
                    {...props}
                  />
                ),
                li: ({ node, ...props }) => (
                  <li className="list-disc" {...props} />
                ),
                p: ({ node, ...props }) => <p className="py-2" {...props} />,
              }}
            />
          </article>
        </div>
      </main>
    </>
  );
}
