import { useParams } from "react-router-dom";
import React, { Component } from "react";

const BACKEND_DOMAIN =
  process.env.REACT_APP_BACKEND_DOMAIN ?? "ENV BACKEND_DOMAIN DEFAULT";
const BACKEND_PORT =
  process.env.REACT_APP_BACKEND_PORT ?? "ENV BACKEND_PORT DEFAULT";

function ProfileID() {
  console.log("swag");
  // interface Result{
  //   created_at: string;
  //   email: string;
  //   email_verified: string;
  //   identities:[
  //     connection: string,
  //     provider: string,
  //     user_id: string,
  //     isSocial: boolean,
  //   ]
  //   name: string;
  //   nickname: string;
  //   picture: string;
  //   updated_at: string;
  //   user_id: string;
  //   last_login: string;
  //   last_ip: string;
  //   logins_count: string;
  // }

  const [APIResponse, setAPIResponse] = React.useState(null);
  const { id } = useParams();

  React.useEffect(() => {
    fetch(`${BACKEND_DOMAIN}${BACKEND_PORT}/auth0_user_id/${id}`)
      .then((res) => res.json())
      .then((res) => setAPIResponse(res));
  }, []);

  console.log(APIResponse);

  return (
    <>
      {SanitizeResponse(APIResponse)}
      <article className="sm:text-center object-center">
        <p className="App-intro">
          {APIResponse?.picture && (
            <img
              className="mx-auto border-double border-black border-8 rounded-full w-50"
              src={APIResponse.picture}
            />
          )}
          {APIResponse?.nickname && APIResponse.nickname}
        </p>
      </article>
    </>
  );
}

function SanitizeResponse(response) {
  console.log("Santitize response,", response);
  // console.log(response.email);

  // WHAT WE KNOW SO FAR! 1. do not stringify or parse. 2. cannot return anything. 3. must have object keys. 4. EMAIL IS M A D GLITCHTY
}

export default ProfileID;
