function FeaturesList(){
    return(
        <section className="p-6 dark:bg-gray-800 dark:text-gray-100">
        <div className="container mx-auto">
          <span className="block mb-2 text-xs font-medium tracking-widest text-center uppercase dark:text-primary-400">
            Features
          </span>
          <h2 className="text-5xl font-bold text-center dark:text-gray-50">
            Features of Envolvly
          </h2>
          <div className="grid gap-6 my-16 lg:grid-cols-3">
            <div className="flex flex-col p-8 space-y-4 rounded-md dark:bg-gray-900">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 text-xl font-bold rounded-full dark:bg-primary-400 dark:text-gray-900">
                1
              </div>
              <p className="text-xl font-semibold">
                <b>Collaborate.</b> Envolvly features an array of tools to create opportunities for your audience to engage directly with content itself. Creators have the ability to schedule upcoming content and list various ways for the audience to involve themselves in the process, allowing users to suggest something as simple as requests, to hands-on uploading of media.
              </p>
            </div>
            <div className="flex flex-col p-8 space-y-4 rounded-md dark:bg-gray-900">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 text-xl font-bold rounded-full dark:bg-primary-400 dark:text-gray-900">
                2
              </div>
              <p className="text-xl font-semibold">
                <b>Connect.</b> Our envolvment system provides viewers with the chance to feel like they are part of the content-creation process itself, helping you build a stronger connection with your community. The creator is capable of engaging with their viewers on a far more personal level than any other platform, only bounded by their creativity
              </p>
            </div>
            <div className="flex flex-col p-8 space-y-4 rounded-md dark:bg-gray-900">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 text-xl font-bold rounded-full dark:bg-primary-400 dark:text-gray-900">
                3
              </div>
              <p className="text-xl font-semibold">
                <b>Commission.</b> Creators looking to expand their revenue stream have the ability to utilize our tools as a commissioning platform, offering services and opportunities unique to the platform you have built. Creators have full control of when and how they choose to promote their services, including the ability to auction off particular offerings.
              </p>
            </div>
            <div className="flex flex-col p-8 space-y-4 rounded-md dark:bg-gray-900">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 text-xl font-bold rounded-full dark:bg-primary-400 dark:text-gray-900">
                4
              </div>
              <p className="text-xl font-semibold">
                <b>Centralize.</b> Envolvly allows you to bring your audience together and give every member of your community a role to play. We have designed our platform to support individualizing every member of a creator's community, allowing you to centralize and focus your viewers into one community.
              </p>
            </div>
            <div className="flex flex-col p-8 space-y-4 rounded-md dark:bg-gray-900">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 text-xl font-bold rounded-full dark:bg-primary-400 dark:text-gray-900">
                5
              </div>
              <p className="text-xl font-semibold">
                <b>Crowdfund.</b> In addition to envolvments and commissions, Envolvly allows creators to host their own unique, centralized, and personal crowdfunding campaigns within their own pages. This centralizes donors onto a single and personalized platform to contribute towards your funding goals, without the restrictions and dilution from other crowdfunding platforms.
              </p>
            </div>
            <div className="flex flex-col p-8 space-y-4 rounded-md dark:bg-gray-900">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 text-xl font-bold rounded-full dark:bg-primary-400 dark:text-gray-900">
                6
              </div>
              <p className="text-xl font-semibold">
                <b>Charity.</b> Envolvly allow creators to use our platform at a significantly reduced fee if associated with any form of charity. We encourage creators to utilize their platform to contribute towards a better world, through the use of our tools and features to spread involvement throughout your community.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
}

export default FeaturesList;