import { useEffect, useState } from "react";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

const backend_domain = process.env.REACT_APP_BACKEND_DOMAIN;
const backend_port = process.env.REACT_APP_BACKEND_PORT;

interface PaymentProps {
  price: number;
}

export default function Payment() {
  const [data, setData] = useState<any>(""); // Use 'any' as a temporary solution
  const { getAccessTokenSilently } = useAuth0();
  const { id } = useParams(); // web addres thingy
  const { user, isAuthenticated } = useAuth0();

  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    fetch(`${backend_domain}${backend_port}/payment/config`).then(async (r) => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  useEffect(() => {
    fetch(`${backend_domain}${backend_port}/payment/create-payment-intent`, {
      method: "POST",
      body: JSON.stringify({}),
    }).then(async (r) => {
      const { clientSecret } = await r.json();
      setClientSecret(clientSecret);
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        const response = await fetch(
          `${backend_domain}${backend_port}/post/${id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const postData = await response.json();
        setData(postData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [getAccessTokenSilently, id]);

  console.log("data", data);

  return (
    <>
      {isAuthenticated && data && (
        <div className="mx-auto w-9/12 m-10">
          <h1 className="font-bold text-2xl">Checkout</h1>
          <div className="content-center flex flex-row">
            <div className="rounded-l-xl bg-gray-200 md:basis-1/2 p-10">
              Payment details
              <div className="flex flex-row border-gray-300 border-2">
                <div className="w-1/4">
                  <img src={data.imgUrl} className="aspect-video" />
                </div>
                <div>
                  <div className="font-bold">{data.title}</div>
                  <div className="text-sm">
                    Content published by{" "}
                    <Link to={`/profile/${data.username}`}>
                      <span className="underline">{data.username}</span>
                    </Link>
                  </div>
                </div>
                <div className="w-1/8">${data.price.price}</div>
              </div>
              <div className="text-xs text-center text-gray-700">
                {" "}
                Payment secured by <span className="font-bold">STRIPE</span>.
                You'll be taken to a thank you page after the payment.
                <Link to="/terms-and-privacy">
                  <div className="underline">Terms and Privacy</div>
                </Link>
              </div>
            </div>

            <div className="rounded-r-xl bg-primary-200 md:basis-1/2 p-10">
              {clientSecret && stripePromise && (
                <Elements
                  stripe={stripePromise}
                  options={{ clientSecret, appearance: { theme: "stripe" } }}
                >
                  <CheckoutForm price={data.price.price} />
                </Elements>
              )}
            </div>
          </div>
        </div>
      )}
      {isAuthenticated && !data && <div>envolvment ID not found BOZO!</div>}
      {!isAuthenticated && <div>log in to make an envolvement bozo</div>}
    </>
  );
}

function CheckoutForm(props: PaymentProps) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<String | undefined | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/completion`,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsProcessing(false);
  };

  // maybe have it display price instead of pay now?
  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button
        className="w-full mt-5 p-2 bg-black text-white rounded-md "
        disabled={isProcessing || !stripe || !elements}
        id="submit"
      >
        <span id="button-text">
          {isProcessing ? "Processing ... " : `Pay $${props.price} now!`}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
