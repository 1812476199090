import { Route, Routes } from "react-router-dom";

import ProfileID from "./ProfileID";
import ProfileSearch from "./ProfileSearch";
import ProfileSearchResults from "./ProfileSearchResults";
import Profile from "./Profile";

export default function ProfilePage(){
    return(
        <>
        <Routes>
          
        <Route index element={<Profile />} />
        <Route path="/:id" element={<ProfileID />} />
        <Route path="/search" element={<ProfileSearch />} />
        <Route path="/search/:id" element={<ProfileSearchResults />} />
        </Routes>
        </>
    )
}