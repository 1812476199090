import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

export default function ExploreModal(props) {
  const [open, setOpen] = useState(false);

  const cancelButtonRef = useRef(null);

  // Function to open the modal
  const openModal = () => {
    setOpen(true);
  };

  console.log("test", props.props);

  const publishedDate = new Date(props.props.datePublished);

  function formatDate(string) {
    var options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(string).toLocaleDateString([], options);
  }

  return (
    <>
      <button onClick={openModal}>
        Open Modal
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-xl text-center font-semibold leading-6 text-gray-500 border=solid border-gray border-2"
                          >
                            {props.props.title}
                            <div className="text-xs">
                              {props.props.previewText}
                            </div>
                          </Dialog.Title>
                          <div className="mt-2">
                            <img
                              src={props.props.imgUrl}
                              className="aspect-video border border-2 border-black"
                            />
                            <p className="text-sm text-gray-500">
                              {props.props.description !== null
                                ? props.props.description
                                : props.props.previewText}
                            </p>
                          </div>
                          <div className="py-4 text-xs">
                            <div className="flex flex-wrap">
                              <div className="w-1/2 truncate flex flex-row">
                                <img
                                  src={props.props.userPicture}
                                  className="border-gray-500 rounded-3xl border h-8 w-8"
                                />
                                <div className="pl-2 flex flex-col">
                                  <div className="flex items-center font-bold md:text-md text-black align-middle">
                                    <p className="truncate">
                                      {props.props.username}
                                    </p>
                                  </div>
                                  {props.props.category} ● Posted{" "}
                                  {formatDate(publishedDate)}
                                </div>
                              </div>
                              <div className="w-1/2">
                                <div className="border border-2 border-gray text-center text-xl text-gray-500">
                                  {props.props.quantity.unlimited
                                    ? "Unlimited"
                                    : `${
                                        props.props.quantity.quantity -
                                        props.props.quantity.currentEnvolves
                                      }/${props.props.quantity.quantity} Left`}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="grid place-items-center p-2">
                            <button className="bg-gray-800 border-black rounded-xl text-white py-2 px-52">
                              <Link to={`/payment/${props.props._id}`}>
                                {props.props.price.free
                                  ? "Free"
                                  : `$${props.props.price.price}`}
                              </Link>
                            </button>
                          </div>
                          <div className="text-xs text-center text-gray-700">
                            {" "}
                            Payment secured by{" "}
                            <span className="font-bold">STRIPE</span>. You'll be
                            taken to a thank you page after the payment.
                            <Link to="/terms-and-privacy">
                              <div className="underline">Terms and Privacy</div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </button>
    </>
  );
}
