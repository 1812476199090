import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const EnvolvementThumbnailSkeleton = ({ cards }) => {
  return (
    <SkeletonTheme baseColor="#313131" highlightColor="#525252">
      {Array(cards)
        .fill(0)
        .map((item, i) => (
          <div className="p-2 border border-1 border-gray-400" key={i}>
            <div className="aspect-video">
              <Skeleton
                containerClassName="flex-1"
                style={{ height: "100%" }}
              />
            </div>
            <div className="text-md text-gray-900 font-bold">
              <Skeleton count={2} />
            </div>
            <div className="h-12">
              <p className="text-xs line-clamp-3">
                <Skeleton count={2} />
              </p>
            </div>
            <div className="py-2 font-semibold">
              {" "}
              <div>
                <Skeleton width={90} />
              </div>
              <div>
                <Skeleton width={40} />
              </div>
            </div>
            <div className="border border-black col-span-full sm:col-span-6 shadow-md"></div>

            <div className="text-xs">
              <Skeleton circle height={40} width={40} />
            </div>
            <Skeleton width={100} />
          </div>
        ))}
    </SkeletonTheme>
  );
};

export default EnvolvementThumbnailSkeleton;
