import Timeline from "./Timeline";
import FeaturesList from "./FeaturesList";

function FeaturesPage(){
    return(
        <>
        <FeaturesList />
        <Timeline />
      </>
    )
}

export default FeaturesPage;