import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

import ExploreModal from "./ExploreModal";
import EnvolvementThumbnailSkeleton from "./EnvolvementThumbnailSkeleton";

const backend_domain = process.env.REACT_APP_BACKEND_DOMAIN;
const backend_port = process.env.REACT_APP_BACKEND_PORT;

function ExplorePage() {
  const { user, isAuthenticated } = useAuth0();

  const [showMore, setShowMore] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        const response = await fetch(
          `${backend_domain}${backend_port}/post/pull`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const postData = await response.json();
        setData(postData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [getAccessTokenSilently]);

  // DATE SETUPS!

  console.log(data);

  const endDate = new Date(data.endDate?.endDate);
  const submissionsDeadlineDate = new Date(
    data.submissions?.submissionsDeadline
  );
  const publishedReleaseDate = new Date(data.published?.publishedReleaseDate);
  const datePublished = new Date(data.datePublished);
  const targetEnvolvmentDate = new Date(data?.targetEnvolvmentDate);

  return (
    <>
      {isAuthenticated && (
        <div className="App">
          <div className="py-2 md:px-14 px-4 text-3xl font-bold">Discover</div>
          <div className="border border-black border-1 col-span-full sm:col-span-6 shadow-md"></div>
          <div className="pt-2 pb-4 md:px-14 px-4 text-xl font-bold">
            Explore{" "}
            <span className="text-primary-500">{data.length} Envolvments</span>
          </div>
          <div className="md:px-14 pb-6 px-4 grid grid-cols-3 grid-cols-2 gap-4">
            {isLoading && <EnvolvementThumbnailSkeleton cards={8} />}
            {data.map((data) => {
              return (
                <>
                  <EnvolvementThumbnail data={data} />
                </>
              );
            })}
          </div>
          <Link to={`/app/create`}>
            <button className="mx-auto bg-primary-300 text-white border rounded-xl p-2 text-md font-bold">
              Create new Envolvement
            </button>
          </Link>
        </div>
      )}
      {isAuthenticated && !data && <div>err0r</div>}
      {!isAuthenticated && <div>log in to make an envolvement bozo</div>}
    </>
  );
}

const EnvolvementThumbnail = (props) => {
  const [posterImage, setPosterImage] = useState("");

  // useEffect(() => {
  //   // GET request using fetch inside useEffect React hook
  //   fetch(`http://localhost:3001/get_user_image_by_id/${props.data.username}`)
  //     .then((res) => {
  //       if (!res.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       return res.json();
  //     })
  //     .then((posterImg) => setPosterImage(posterImg))
  //     .catch((error) => console.error("Error fetching poster image:", error));

  //   // empty dependency array means this effect will only run once (like componentDidMount in classes)
  // }, []);

  const endDate = new Date(props.data.endDate.endDate);
  const publishedDate = new Date(props.data.datePublished);

  var sAfterDays = "";

  function formatDate(string) {
    var options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(string).toLocaleDateString([], options);
  }

  function endDateCalculate(dateString) {
    // Convert the input date string to a Date object
    const inputDate = new Date(dateString);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in milliseconds between the two dates
    const differenceInMilliseconds = inputDate - currentDate;

    // Convert the difference to days
    const differenceInDays = Math.floor(
      differenceInMilliseconds / (1000 * 60 * 60 * 24)
    );

    return differenceInDays;
  }

  if (endDateCalculate(endDate) > 1) sAfterDays = "s";

  return (
    <>
      {/* <Link to={`/app/envolvement/${props.data._id}`}> */}
      <div className="p-2 border border-1 border-gray-400 over:bg-gray-300 hover:scale-110 hover:shadow-lg transition duration-150">
        <img
          src={props.data.imgUrl}
          className="aspect-video border border-2 border-black"
        />

        <div className="text-md text-gray-900 font-bold">
          {props.data.title}
        </div>
        <div className="h-12">
          <p className="text-xs line-clamp-3">{props.data.previewText}</p>
        </div>
        <div className="py-2 font-semibold">
          {" "}
          <div>
            {props.data.endDate.none
              ? `${endDateCalculate(endDate)} Day${sAfterDays} Left!`
              : ``}{" "}
          </div>
          <div className="flex justify-between text-xl">
            <div>
              {props.data.quantity.unlimited
                ? `Unlimited`
                : `${
                    props.data.quantity.quantity -
                    props.data.quantity.currentEnvolves
                  }/${props.data.quantity.quantity} Left`}{" "}
            </div>
            <div>
              {props.data.price.free ? `Free` : `$${props.data.price.price}`}{" "}
            </div>
          </div>
        </div>
        <div className="border border-black col-span-full sm:col-span-6 shadow-md"></div>

        <div className="text-xs">
          <div className="truncate flex flex-row">
            <img
              src={props.data.userPicture}
              className="border-gray-500 rounded-3xl border h-12 w-12"
            />

            <div className="pl-2 flex flex-col">
              <div className="flex items-center font-bold md:text-xl text-black align-middle">
                <p className="truncate">{props.data.username}</p>
              </div>
              {props.data.category} ● Posted {formatDate(publishedDate)}
            </div>
          </div>
        </div>
        <ExploreModal props={props.data} />
      </div>
      {/* </Link> */}
    </>
  );
};

export default ExplorePage;
