import React, { useState } from "react";
import { HiMenuAlt3 } from "react-icons/hi";
import { MdOutlineDashboard } from "react-icons/md";
// import { RiSettings4Line } from "react-icons/ri";
// import { TbReportAnalytics } from "react-icons/tb";
// import { AiOutlineUser, AiOutlineHeart } from "react-icons/ai";
import { FiMessageSquare } from "react-icons/fi";

import { RiCommunityLine } from "react-icons/ri";
import { AiOutlineHome, AiOutlineVideoCamera } from "react-icons/ai";
import { FaMoneyBillWave } from "react-icons/fa";
import { Link } from "react-router-dom";

const Sidebar = ({ children }) => {
  const menus = [
    { name: "Home", link: "/", icon: AiOutlineHome },
    { name: "Become Envolved", link: "/", icon: RiCommunityLine },
    { name: "Dashboard", link: "/", icon: MdOutlineDashboard, margin: true },
    { name: "Payouts", link: "/", icon: FaMoneyBillWave },
    { name: "Messages", link: "/", icon: FiMessageSquare },
    { name: "My Content", link: "/", icon: AiOutlineVideoCamera, margin: true },
  ];

  let mobile = true;

  if (window.innerWidth <= 768) mobile = false; // closes the sidebar by default on mobile

  const [open, setOpen] = useState(mobile);
  return (
    <div>
      <section className="fixed flex gap-6">
        <div
          className={`bg-envolvlyGray min-h-screen ${
            open ? "w-72" : "w-16"
          } duration-500 text-gray-100 px-4`}
        >
          <div className="md:hidden py-3 flex justify-end">
            <HiMenuAlt3
              size={26}
              className="cursor-pointer"
              onClick={() => setOpen(!open)}
            />
          </div>
          <div className="mt-4 flex flex-col gap-4 relative">
            {menus?.map((menu, i) => (
              <Link
                to={menu?.link}
                key={i}
                className={` ${
                  menu?.margin && "mt-5"
                } group flex items-center text-sm  gap-3.5 font-medium p-2 hover:bg-gray-800 rounded-md`}
              >
                <div>{React.createElement(menu?.icon, { size: "20" })}</div>
                <h2
                  style={{
                    transitionDelay: `${i + 3}00ms`,
                  }}
                  className={`whitespace-pre duration-500 ${
                    !open && "opacity-0 translate-x-28 overflow-hidden"
                  }`}
                >
                  {menu?.name}
                </h2>
                <h2
                  className={`${
                    open && "hidden"
                  } absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
                >
                  {menu?.name}
                </h2>
              </Link>
            ))}
          </div>
        </div>
      </section>
      <div className={`${open && "ml-72"} ${!open && "ml-16"}`}>{children}</div>
    </div>
  );
};

export default Sidebar;
