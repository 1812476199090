import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  const { user, isAuthenticated } = useAuth0();
  const [username, setUsername] = useState("");
  const [title, setTitle] = useState("");
  const [img, setImg] = useState("");
  const [value, setValue] = useState();
  const [description, setDescription] = useState("");
  const [maxEnvolves, setMaxEnvolves] = useState();
  const [tags, setTags] = useState("");
  const [tag1, setTag1] = useState("");
  const [tag2, setTag2] = useState("");
  const [tag3, setTag3] = useState("");


  const [message, setMessage] = useState("");

  var jsondata = {
    type: "BaseEnvolvement",
    username: user,
    title: title,
    img: img,
    value: value,
    tags: {
      tag1: tag1,
      tag2: tag2,
      tag3: tag3,
    },
    description: description,
    maxEnvolves: maxEnvolves,
    currentEnvolves: 0,
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let res = await fetch("http://localhost:3001/post/new-base-envolvement", {
        method: "POST",
        body: JSON.stringify(jsondata),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      console.log(res);
      let resJson = await res.json();
      if (res.status === 200) {
        setUsername(user.name);
        setTitle("");
        setImg("");
        setValue();
        setDescription("");
        setMaxEnvolves("");
        setTags("");
        setTag1("");
        setTag2("");
        setTag3("");
        setMessage("Posted Successfully!");
      } else {
        setMessage("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {isAuthenticated && (
        <div className="App">
          <section className="p-6 dark:bg-gray-800 dark:text-gray-50">
            <form onSubmit={handleSubmit}>
              <fieldset className="grid grid-cols-4 gap-6 p-6 rounded-md shadow-sm dark:bg-gray-900">
                <div className="space-y-2 col-span-full lg:col-span-1">
                  <p className="font-medium">Example Envolvement</p>
                  <p className="text-xs">
                    Fill in info about your Envolvement here! <br></br><br></br>
                    You are currently signed in as: <p className="font-bold">{user.nickname}</p>
                    <img
                className="mx-auto border-black border-8 rounded-full w-20"
                src={user.picture}
                alt={user?.name}
              />
              <br></br>
              Make sure this post follows Envovly Guidelines and local laws & regulations!
                  </p>
                  
                </div>
                <div className="grid grid-cols-6 gap-4 col-span-full lg:col-span-3">
                  <div className="col-span-full sm:col-span-4">
                    <label for="title" className="text-sm">
                      Title
                    </label>
                    <input
                      type="text"
                      value={title}
                      placeholder="Title"
                      onChange={(e) => setTitle(e.target.value)}
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  <div className="col-span-full sm:col-span-1">
                    <label for="firstname" className="text-sm">
                      Max Envolves
                    </label>
                    <input
                      type="number"
                      value={maxEnvolves}
                      placeholder="Max Envolves"
                      onChange={(e) => setMaxEnvolves(e.target.value)}
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  <div className="col-span-full sm:col-span-1">
                    <label for="firstname" className="text-sm">
                      Value
                    </label>
                    <input
                      type="number"
                      value={value}
                      placeholder="Value"
                      onChange={(e) => setValue(e.target.value)}
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  <div className="col-span-full">
                    <label for="Description" className="text-sm mb-2">
                      Description
                    </label>
                    <textarea
                      type="text"
                      value={description}
                      placeholder="Description"
                      onChange={(e) => setDescription(e.target.value)}
                      rows="4"
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    ></textarea>
                  </div>
                  <div className="col-span-full sm:col-span-3">
                    <label for="firstname" className="text-sm">
                      Link
                    </label>
                    <input
                      type="text"
                      value={img}
                      placeholder="Link"
                      onChange={(e) => setImg(e.target.value)}
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />

{/* does not work */}
                  </div> 
                    <div class="col-span-full sm:col-span-3">
                      <label
                        for="formFile"
                        class="mb-2 inline-block text-neutral-700 dark:text-neutral-200"
                      >
                        Choose file to upload{" "}
                      </label>
                      <input
                        class="relative m-0 block p-2.5 w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding py-[0.32rem] px-3 text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100"
                        type="file"
                        id="formFile"
                      />
                  </div>



                  {/* <div className="col-span-full sm:col-span-3">


              <label for="firstname" className="text-sm">Username</label>
                <input
                  type="text"
                  value={username}
                  placeholder="Username"
                  onChange={(e) => setUsername(e.target.value)}
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div> */}
                  <div className="col-span-full sm:col-span-2">
                    <label for="Tag1" className="text-sm">
                      Tags
                    </label>
                    <input
                      type="text"
                      value={tag1}
                      placeholder="Tag1 (optional)"
                      onChange={(e) => setTag1(e.target.value)}
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  <div className="col-span-full sm:col-span-2">
                    <label for="Tag1" className="text-sm">
                      Tags
                    </label>
                    <input
                      type="text"
                      value={tag2}
                      placeholder="Tag2 (optional)"
                      onChange={(e) => setTag2(e.target.value)}
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  <div className="col-span-full sm:col-span-2">
                    <label for="Tag1" className="text-sm">
                      Tags
                    </label>
                    <input
                      type="text"
                      value={tag3}
                      placeholder="Tag3 (optional)"
                      onChange={(e) => setTag3(e.target.value)}
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  className="px-8 py-3 font-semibold rounded dark:bg-gray-100 dark:text-gray-800"
                >
                  Create
                </button>
              </fieldset>
            </form>

            <div className="message">{message ? <p>{message}</p> : null}</div>
          </section>
        </div>
      )}

      {!isAuthenticated && <div>log in to make an envolvement bozo</div>}
    </>
  );
}

export default App;
