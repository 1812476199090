import * as React from "react";
const SVGComponent = (props) => (
  <svg
    id="visual"
    viewBox="0 0 900 1000"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <path
      d="M0 341L21.5 324.3C43 307.7 86 274.3 128.8 272.7C171.7 271 214.3 301 257.2 297.7C300 294.3 343 257.7 385.8 279.3C428.7 301 471.3 381 514.2 414.3C557 447.7 600 434.3 642.8 436C685.7 437.7 728.3 454.3 771.2 429.3C814 404.3 857 337.7 878.5 304.3L900 271L900 0L878.5 0C857 0 814 0 771.2 0C728.3 0 685.7 0 642.8 0C600 0 557 0 514.2 0C471.3 0 428.7 0 385.8 0C343 0 300 0 257.2 0C214.3 0 171.7 0 128.8 0C86 0 43 0 21.5 0L0 0Z"
      fill="#ffffff"
    />
    <path
      d="M0 641L21.5 644.3C43 647.7 86 654.3 128.8 659.3C171.7 664.3 214.3 667.7 257.2 649.3C300 631 343 591 385.8 591C428.7 591 471.3 631 514.2 644.3C557 657.7 600 644.3 642.8 654.3C685.7 664.3 728.3 697.7 771.2 701C814 704.3 857 677.7 878.5 664.3L900 651L900 269L878.5 302.3C857 335.7 814 402.3 771.2 427.3C728.3 452.3 685.7 435.7 642.8 434C600 432.3 557 445.7 514.2 412.3C471.3 379 428.7 299 385.8 277.3C343 255.7 300 292.3 257.2 295.7C214.3 299 171.7 269 128.8 270.7C86 272.3 43 305.7 21.5 322.3L0 339Z"
      fill="#E8E8E8"
    />
    <path
      d="M0 801L21.5 799.3C43 797.7 86 794.3 128.8 801C171.7 807.7 214.3 824.3 257.2 822.7C300 821 343 801 385.8 799.3C428.7 797.7 471.3 814.3 514.2 832.7C557 851 600 871 642.8 881C685.7 891 728.3 891 771.2 872.7C814 854.3 857 817.7 878.5 799.3L900 781L900 649L878.5 662.3C857 675.7 814 702.3 771.2 699C728.3 695.7 685.7 662.3 642.8 652.3C600 642.3 557 655.7 514.2 642.3C471.3 629 428.7 589 385.8 589C343 589 300 629 257.2 647.3C214.3 665.7 171.7 662.3 128.8 657.3C86 652.3 43 645.7 21.5 642.3L0 639Z"
      fill="#ffffff"
    />
    <path
      d="M0 1001L21.5 1001C43 1001 86 1001 128.8 1001C171.7 1001 214.3 1001 257.2 1001C300 1001 343 1001 385.8 1001C428.7 1001 471.3 1001 514.2 1001C557 1001 600 1001 642.8 1001C685.7 1001 728.3 1001 771.2 1001C814 1001 857 1001 878.5 1001L900 1001L900 779L878.5 797.3C857 815.7 814 852.3 771.2 870.7C728.3 889 685.7 889 642.8 879C600 869 557 849 514.2 830.7C471.3 812.3 428.7 795.7 385.8 797.3C343 799 300 819 257.2 820.7C214.3 822.3 171.7 805.7 128.8 799C86 792.3 43 795.7 21.5 797.3L0 799Z"
      fill="#232323"
    />
  </svg>
);
export default SVGComponent;
