import React, { useState, useEffect} from "react";
import { SunIcon, MoonIcon } from '@heroicons/react/24/outline'


function ThemeToggle(){


const [theme, setTheme] = useState("null");

useEffect(() => {
  if(window.matchMedia('(prefers-color-scheme: dark)').matches){
    setTheme('dark');
  }
  else {
    setTheme("light");
  }
}, [])

useEffect(() => {
  if (theme === "dark") {
    document.documentElement.classList.add("dark");
  } else {
    document.documentElement.classList.remove("dark");
  }
}, [theme]);

const handleThemeSwitch = () => {
  setTheme(theme === "dark" ? "light" : "dark");
};

    return(
      <>
      
    <button onClick={handleThemeSwitch}>
    {theme === "dark" &&(<SunIcon className="h-4 w-4" aria-hidden="true" />)}
    {theme === "light" &&(<MoonIcon className="h-4 w-4" aria-hidden="true" />)}
    </button>
    </>
    )
}


export default ThemeToggle;