import React, { useState, useEffect } from "react";
import axios from "axios";

const FileUpload = ({ chooseImgUrl }) => {
  const [isUploadComplete, setIsUploadComplete] = useState(false);

  const [isSendComplete, setIsSendComplete] = useState(false); // sending to img databse

  const [selectedFile, setSelectedFile] = useState(new Blob());

  const [uploadProgress, setUploadProgress] = useState(0);

  const fileSelectedHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsUploadComplete(true);
  };

  const fileUploadHandler = (event) => {
    event.preventDefault(); // wont submit the form
    const fd = new FormData();
    fd.append("image", selectedFile, selectedFile.name);
    setUploadProgress(0);

    // view here https://james-smith23332.imgbb.com/
    axios
      .post(
        "https://api.imgbb.com/1/upload?key=ff344b66b85a7175ae885367a3246c55",
        fd,
        {
          onUploadProgress: (progressEvent) => {
            console.log(
              "Upload Progress: " +
                (progressEvent.loaded / progressEvent.total) * 100 +
                "%"
            );
            setUploadProgress(
              (progressEvent.loaded / progressEvent.total) * 100
            );
          },
        }
      )
      .then((res) => {
        //console.log(res);
        chooseImgUrl(res.data.data.image.url); // passes to parent
        setIsSendComplete(true);
        setIsUploadComplete(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div class="col-span-full sm:col-span-3">
        <label for="formFile" className="text-sm mb-2">
          Choose file to upload
        </label>
        <div class="flex items-center justify-center w-full">
          <label
            for="dropzone-file"
            class={`${
              isSendComplete ? "bg-primary-500" : "bg-gray-50"
            } flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-100`}
          >
            <div
              class={`${
                isSendComplete ? "hidden" : ""
              } flex flex-col items-center justify-center pt-5 pb-6`}
            >
              <svg
                aria-hidden="true"
                class="w-10 h-10 mb-3 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                ></path>
              </svg>
              <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span class="font-semibold">Click to upload</span> or drag and
                drop
              </p>
              <p class="text-xs text-gray-500 dark:text-gray-400">
                SVG, PNG, JPG or GIF (MAX. 800x400px)
              </p>
            </div>
            <div>
              <p class="mb-2 text-sm text-white dark:text-gray-400">
                {uploadProgress}% Uploaded
              </p>
              <CircleFill percentage={uploadProgress} />
            </div>
            <div
              class={`${
                isSendComplete ? "" : "hidden"
              } flex flex-col items-center justify-center pt-5 pb-6`}
            >
              <p class="mb-2 text-sm text-white dark:text-gray-400">
                Image uploaded successfully
              </p>
            </div>
            <input
              id="dropzone-file"
              type="file"
              class="hidden"
              onChange={fileSelectedHandler}
            />
            <button
              id="uploadButton"
              onClick={fileUploadHandler}
              disabled={!isUploadComplete}
              className={`${
                isUploadComplete ? "" : "hidden"
              } p-2 text-white border rounded-xl bg-primary-500`}
            >
              Upload
            </button>
          </label>
        </div>
      </div>
    </>
  );
};

const CircleFill = ({ percentage }) => {
  const fillPercentage = Math.min(Math.max(percentage, 0), 100);

  return (
    <div className="relative w-20 h-20">
      <svg
        viewBox="0 0 100 100"
        className="absolute w-full h-full transform transition-transform"
      >
        <circle
          cx="50"
          cy="50"
          r="45"
          fill="transparent"
          stroke="bg-primary-500"
          strokeWidth="10"
          strokeDasharray="283"
          strokeDashoffset={(283 * (100 - fillPercentage)) / 100}
        />
      </svg>
    </div>
  );
};

export default FileUpload;
