import { Link } from "react-router-dom";
import blog from "./blogContent";

export default function BlogPage() {
  const reversedBlog = blog.reverse();
  return (
    <section className="bg-envolvlyGray py-6">
      <div className="container mx-auto flex flex-col items-center justify-center p-3 space-y-8 md:p-10 md:px-24 xl:px-48">
        <h1 className="text-5xl font-bold leading-none text-center">
          Envolvly Blog
        </h1>
      </div>

      {reversedBlog.map((item) => (
      <div className="text-gray-50">
        <div className="container grid grid-cols-12 mx-auto bg-[#121212]">
          <div className="hidden lg:inline bg-no-repeat bg-cover bg-primary-700 col-span-full lg:col-span-4">
            <img
              src={item.img}
              alt={item.title}
              className="w-[100%] h-[100%] relative z-[5]"
            />
          </div>
          <div className="flex flex-col p-6 col-span-full row-span-full lg:col-span-8 lg:p-10">
            <div className="flex justify-start">
            </div>
            <h1 className="text-3xl font-semibold">{item.title}</h1>
            <p className="flex-1 pt-2">
                {item.description}
            </p>
            <Link to={`/blog/${item.id}`}>
            <div
              className="inline-flex items-center pt-2 pb-6 space-x-2 text-sm text-primary-400"
            >
              <span>Read more</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-4 h-4"
              >
                <path
                  fillRule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            </Link>
            <div className="flex items-center justify-between pt-2">
              <div className="flex space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-5 h-5 dark:text-gray-400"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="self-center text-sm">by {item.author} | {item.date}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
              ))}
    </section>
  );
}
