import { Link } from "react-router-dom";

export default function Survey() {
  return (
    <>
    <section className="py-6 text-gray-100">
      <div className="bg-primary-400 rounded-lg container mx-auto flex flex-col items-center justify-center p-3 space-y-8 md:p-10 md:px-24 xl:px-48">
        <h1 className="text-5xl font-bold leading-none text-center">
          Take our creator survey
        </h1>
        <p className="pt-2 pb-8 text-xl font-medium text-center">
          Recieve Early Access and rewards for helping our research
        </p>
        <div className="flex flex-row items-center self-center justify-center flex-shrink-0 shadow-md lg:justify-end">
          <div className="flex flex-row">
          {/* <form onSubmit={handleSubmit}> */}
            <button
              type="submit"
              className="px-40 py-6 text-xl font-semibold rounded-lg bg-white text-gray-900"
            >
              Survey
            </button>
            {/* </form> */}
          </div>
        </div>
      </div>
    </section>
    {/* https://github.com/adrianhajdin/project_hoobank/blob/main/src/components/Business.jsx */}
    </>
  );
}
