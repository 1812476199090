
import {CurrencyDollarIcon, FireIcon, UserGroupIcon} from '@heroicons/react/24/solid'

const features = [
  {
    name: 'Earn Revenue',
    description:
      'Offer flexible opportunities for your viewers to interact with your platform.',
    icon: CurrencyDollarIcon,
  },
  {
    name: 'Prosper Viewership',
    description:
      'Develop intimate relationships between yourself and your audience.',
    icon: FireIcon,
  },
  {
    name: 'Crowdfund Creativity',
    description:
      'Bring life to your creative ideas through viewer crowdfunding.',
    icon: UserGroupIcon,
  }
]

function Bar() {
  return (
    <div className="py-10 sm:py-10 lg:py-10">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="sm:text-center">
          <h2 className="text-lg font-semibold leading-8 text-primary-600">Features</h2>
          <p className="text-white mt-2 text-3xl font-bold tracking-tight  sm:text-4xl">A better way to connect with Audiences</p>
        </div>

        <div className="mt-20 max-w-lg sm:mx-auto md:max-w-none drop-shadow-2xl">
          <div className="grid grid-cols-1 gap-y-6 md:grid-cols-3 md:gap-y-5">
            {features.map((feature) => (
              <div key={feature.name} className="group bg-slate-50 rounded-lg relative flex flex-col gap-6 sm:flex-row md:flex-col lg:flex-row hover:scale-110 hover:shadow-lg transition duration-150">
                <div className="sm:min-w-0 sm:flex-1">
                <div className="flex items-center justify-center rounded-xl sm:shrink-0">
                  <feature.icon className="h-12 w-12 fill-gray-600 group-hover:fill-primary-600" aria-hidden="true" />
                </div>
                  <p className="text-black text-center text-lg font-semibold leading-8 ">{feature.name}</p>
                  <p className="text-gray-600 p-2 mt-2 text-base leading-7 ">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
  

export default Bar;