import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Skeleton from "react-loading-skeleton";

import Activity from "./envolvment/Activity";
import Submission from "./envolvment/Submission";

const backend_domain = process.env.REACT_APP_BACKEND_DOMAIN;
const backend_port = process.env.REACT_APP_BACKEND_PORT;

function Envolvement() {
  const [ActivitySelected, setActivitySelected] = useState(false);
  const [SubmissionSelected, setSubmissionSelected] = useState(false);

  const { id } = useParams();
  const { user, isAuthenticated } = useAuth0();

  const [showMore, setShowMore] = useState(false);
  const [data, setData] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        const response = await fetch(
          `${backend_domain}${backend_port}/post/${id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const postData = await response.json();
        setData(postData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    setIsLoading(false);
  }, [getAccessTokenSilently, id]);

  const activityClick = () => {
    setActivitySelected(true);
    setSubmissionSelected(false);
  };

  const submissionClick = () => {
    setActivitySelected(false);
    setSubmissionSelected(true);
  };

  // DATE SETUPS!

  const endDate = new Date(data.endDate?.endDate);
  const submissionsDeadlineDate = new Date(
    data.submissions?.submissionsDeadline
  );
  const publishedReleaseDate = new Date(data.published?.publishedReleaseDate);
  const datePublished = new Date(data.datePublished);
  const targetEnvolvmentDate = new Date(data?.targetEnvolvmentDate);

  return (
    <>
      {isAuthenticated && data && (
        <div className="App">
          <section className="md:p-6 dark:bg-gray-800 dark:text-gray-50">
            <fieldset className="md:px-32 mx-auto grid grid-cols-4 gap-6 p-6 rounded-md shadow-sm dark:bg-gray-900">
              <div className="px-6 grid grid-cols-6 gap-4 col-span-full lg:col-span-4 border rounded-xl border-gray-200">
                <div className="py-2 col-span-full sm:col-span-6">
                  <div className="flex flex-row border border-grey-300 border-3">
                    <div className="basis-1/6"></div>
                    <div className="basis-4/6 text-xl text-center">
                      {data.title || <Skeleton />}
                    </div>
                    <div className="basis-1/6 text-white flex items-center justify-center text-xl border rounded-xl w-full bg-primary-500">
                      {data.active ? "ACTIVE" : "INACTIVE"}{" "}
                      {data.active || <Skeleton />}{" "}
                    </div>
                  </div>
                </div>

                <div className="col-span-full sm:col-span-3">
                  <div className="">
                    <img
                      src={
                        data.imgUrl !== ""
                          ? data.imgUrl
                          : "https://upload.wikimedia.org/wikipedia/commons/b/b1/Missing-image-232x150.png"
                      }
                      alt="Example Envolvment"
                      className="w-full h-auto max-w-full mx-auto aspect-video"
                    />
                    {data.imgUrl || <Skeleton />}
                  </div>
                </div>

                <div className="col-span-full sm:col-span-3">
                  <div className="h-5/6 border rounded-xl w-full">
                    <div className="text-sm pt-2">
                      <div className="">
                        {showMore
                          ? data.description
                          : `${data.description?.substring(0, 250)}`}
                      </div>
                      <div className="flex justify-center items-center">
                        <button
                          className="p-2 btn underline"
                          onClick={() => setShowMore(!showMore)}
                        >
                          {showMore ? "Show less" : "Show more"}
                        </button>
                      </div>
                    </div>
                    <div className="">
                      <div>
                        <label className="text-md mb-2">
                          Pricing Information
                        </label>
                        <div className="sm:text-md text-sm font-bold">
                          {data.price ? (
                            data.price.free ? (
                              "Free!"
                            ) : (
                              `${data.price.price}$`
                            )
                          ) : (
                            <Skeleton />
                          )}{" "}
                          |{" "}
                          {data.quantity ? (
                            data.quantity.unlimited ? (
                              "Unlimited entries!"
                            ) : (
                              `${data.quantity.quantity} / ${data.quantity.total} slots available!`
                            )
                          ) : (
                            <Skeleton />
                          )}
                        </div>
                      </div>
                      {/* <div className="font-bold">
                      {targetEnvolvmentDate
                        ? "s"
                        : `Releasing | ${data.targetEnvolvmentDate}`}
                    </div> */}
                      <div
                        className={`md:pt-8 ${
                          data.published.publishedReleaseDate != null
                            ? ""
                            : "hidden"
                        }`}
                      >
                        <span className="font-bold">Releasing </span>|{" "}
                        {publishedReleaseDate.toLocaleDateString()}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-span-2 sm:col-span-3">
                  <div className="text-sm">
                    {data.category} | {datePublished.toLocaleDateString()}
                  </div>
                </div>

                <div className="col-span-full sm:col-span-6">
                  <div className="border-gray-500 border"></div>
                </div>

                <div className="col-span-full sm:col-span-6">
                  <div className="text-sm flex justify-between md:px-48 px-16">
                    <div>
                      <button onClick={activityClick}>Activity</button>
                    </div>
                    <div>
                      <button onClick={submissionClick}>Submission</button>
                    </div>
                  </div>
                </div>

                <div className="md:px-6 grid grid-cols-6 gap-4 col-span-full border rounded-xl border-gray-200">
                  <div className="col-span-full sm:col-span-6">
                    <Activity
                      isSelected={ActivitySelected}
                      posterID={data?.userID}
                      currentUserID={user?.sub}
                      isActive={data?.isActive} // hard coded for now, fix this later
                      postID={data._id}
                      existingPostJSON={data.activityPostJSON}
                    />
                    <Submission
                      isSelected={SubmissionSelected}
                      posterID={data?.userID}
                      currentUserID={user?.sub}
                      isActive={data?.submissions.submissionsActive} // hard coded for now, fix this later
                      postID={data._id}
                      existingPostJSON={data.activityPostJSON}
                      SubmissionIDPair={data.submissions.submissionsIDPair}
                      submissionContent={data.submissions.submissionContent}
                    />
                  </div>
                </div>
              </div>
            </fieldset>
          </section>
        </div>
      )}
      {isAuthenticated && !data && <div>envolvment ID not found BOZO!</div>}
      {!isAuthenticated && <div>log in to make an envolvement bozo</div>}
    </>
  );
}

export default Envolvement;
