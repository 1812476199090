/* This example requires Tailwind CSS v3.0+ */
import { useState } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import LoginButton from "./miscComponents/LoginButton";
import LogoutButton from "./miscComponents/LogoutButton";
import ThemeToggle from "./miscComponents/ThemeToggle";

import envolvlyLogo from "./assets/webp/navbarLogo.webp";

const navigation = [
  { name: "Demo", href: "demo", scroll: "1450" },
  { name: "Blog", href: "blog", scroll: "2400" },
  { name: "Apply", href: "apply", scroll: "3300" },
];

function Navbar() {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate(); // redirects to home page

  return (
    <div className="text-white p-2 backdrop-blur-md shadow-md sticky top-0 left-0 right-0 z-10 w-full isolate bg-envolvlyGray">
      <div className="px-4 pt-4 lg:px-8">
        <div>
          <nav
            className="flex h-9 items-center justify-between"
            aria-label="Global"
          >
            <div className="flex lg:min-w-0 lg:flex-1" aria-label="Global">
              <Link to="/">
                <span className="-m-1.5 p-1.5">
                  <span className="sr-only">Envolvly</span>
                   <img src={envolvlyLogo} className="h-8" alt=""/>

                </span>
              </Link>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:min-w-0 lg:flex-1 lg:justify-center lg:gap-x-12">
              {navigation.map((item) => (
                <button onClick={() => {
                  navigate("/"); // brings to home page
                  window.scrollTo({
                    top: item.scroll,
                    behavior: "smooth",
                  });
                }}>
                  <span
                    key={item.name}
                    className="font-semibold hover:text-gray-900"
                  >
                    {item.name}
                  </span>
                </button>
              ))}
            </div>
            <div className="hidden lg:flex lg:min-w-0 lg:flex-1 lg:justify-end">
              {/* <div className="inline-block rounded-lg px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm ring-gray-900/10 hover:ring-gray-900/20">
                <ThemeToggle />
              </div> */}
            {isAuthenticated && (<div className="inline-block rounded-lg px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm ring-1 ring-gray-900/10 hover:ring-gray-900/20">
              <button className="bg-primary-500 hover:bg-primary-700 text-white font-bold py-2 px-4 border border-primary-700 rounded">
                  {" "}
                  <Link to="/profile">Profile</Link>{" "}
                </button>
              </div>)}
              <div className="inline-block rounded-lg px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                <LoginButton />
                <LogoutButton />
              </div>
            </div>
          </nav>
          <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <Dialog.Panel
              focus="true"
              className="fixed inset-0 z-10 overflow-y-auto bg-white dark:bg-gray-800 px-6 py-6 lg:hidden"
            >
              <div className="flex h-9 items-center justify-between">
                <div className="flex">
                  <Link to="/">
                    <span className="-m-1.5 p-1.5">
                      <span className="sr-only">Envolvly</span>
                      <img
                        className="h-8"
                        src="https://i.imgur.com/LeTB3Yi.png"
                        alt=""
                      />
                    </span>
                  </Link>
                </div>
                <div className="flex">
                  <button
                    type="button"
                    className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-inherit"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <div>
                     <button onClick={() => {
                      navigate("/"); // brings to home page
                      window.scrollTo({
                        top: item.scroll,
                        behavior: "smooth",
                      });
                    }}>
                        <span
                          key={item.name}
                          className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-inherit hover:bg-gray-400/10"
                        >
                          {item.name}
                        </span>
                      </button>
                      </div>
                    ))}
                  </div>
                  <div className="py-6">
                    <div className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-inherit hover:bg-gray-400/10">
                      <button>
                        {" "}
                        <Link to="/profile">Profile</Link>{" "}
                      </button>
                    </div>
                    <div className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-inherit hover:bg-gray-400/10">
                      <LoginButton />
                      <LogoutButton />
                    </div>
                    <div className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-inherit hover:bg-gray-400/10">
                      <ThemeToggle />
                    </div>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
