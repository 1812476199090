import exampleEnvolvement from "../assets/webp/exampleEnvolvement5.webp"

function Checklist() {
  return (
    <section className="p-12 md:px-60 text-black">
      <div className="flex flex-col md:flex-row justify-center items-center">
      <div className="px-4 py-12 mx-auto">
        <div className="grid gap-4 mx-4 sm:grid-cols-12">
          <div className="col-span-12 sm:col-span-3">
            <div className="text-center sm:text-left mb-14 before:block before:w-24 before:h-3 before:mb-5 before:rounded-md before:mx-auto sm:before:mx-0 before:bg-primary-400">
              <h3 className="text-3xl font-semibold">Involve your audience</h3>
              <span className="text-sm font-bold tracking-wider uppercase text-black-400">
                Earn from your passion
              </span>
            </div>
          </div>
          <div className="relative col-span-12 px-4 space-y-6 sm:col-span-9">
            <div className="col-span-12 space-y-12 relative px-4 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:bg-gray-700">
              <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-primary-400">
                <h3 className="text-xl font-semibold tracking-wide">
                  Set up your page
                </h3>
              </div>
              <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-primary-400">
                <h3 className="text-xl font-semibold tracking-wide">
                  Create an Envolvement
                </h3>
              </div>
              <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-primary-400">
                <h3 className="text-xl font-semibold tracking-wide">
                  Start earning revenue
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-8">
        <img src={exampleEnvolvement} className="md:max-w-lg max-w-sm h-auto" alt="Example Envolvement Image"/>
      </div>
      </div>
    </section>
  );
}

export default Checklist;
