import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import { Auth0Provider } from "@auth0/auth0-react"; // Login stuff, by Auth 0

const AUTH0_DOMAIN: string =
  process.env.REACT_APP_AUTH0_DOMAIN ?? "AUTH0_DOMAIN default";
const AUTH0_CLIENT_ID: string =
  process.env.REACT_APP_AUTH0_CLIENT_ID ?? "AUTH0_CLIENT_ID default";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0Provider
        domain={AUTH0_DOMAIN}
        clientId={AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        audience="https://envolvly/api"
        scope="openid profile email"
      >
        <App />
      </Auth0Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
