import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
  const { logout, isAuthenticated } = useAuth0();
  return (
    <>
      {isAuthenticated && ( // returns sign out button if user is authenticated, else returns blank
        <button className="bg-primary-500 hover:bg-primary-700 text-white font-bold py-2 px-4 border border-primary-700 rounded" onClick={() => logout()}>Sign Out</button>
      )}
    </>
  );
};

export default LogoutButton;
