import { useState, useEffect } from "react";
import FileUpload from "../FileUpload";
import { useAuth0 } from "@auth0/auth0-react";
import SVGComponent from "./SVGComponent";

const backend_domain = process.env.REACT_APP_BACKEND_DOMAIN;
const backend_port = process.env.REACT_APP_BACKEND_PORT;
interface Item {
  title: string;
  description: string;
  imgUrl: string;
  datePublished: string;
}

interface selectedProps {
  isSelected: boolean;
  posterID: string;
  currentUserID: string;
  isActive: boolean;
  postID: string;
  existingPostJSON: string;
}

export default function Activity(props: selectedProps) {
  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    fetch(
      `${backend_domain}${backend_port}/get_user_image_by_id/${props.posterID}`
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((posterImg) => setPosterImage(posterImg))
      .catch((error) => console.error("Error fetching poster image:", error));

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  const { getAccessTokenSilently } = useAuth0();

  const [posterImage, setPosterImage] = useState("");

  const [title, setTitle] = useState("");

  const [postActive, setPostActive] = useState(false);

  const [description, setDescription] = useState("");

  const [imgUrl, setImgUrl] = useState("");

  const [newPost, setNewPost] = useState(false);

  const forceViewerMode = true;

  const chooseImgUrl = (imgUrl: any) => {
    setImgUrl(imgUrl);
    console.log("BALLS!", imgUrl);
  };

  let handleSubmit = async (e: any) => {
    var jsondata = {
      postID: props.postID,
      title: title,
      description: description,
      imgUrl: imgUrl,
      existingPostJSON: props.existingPostJSON,
    };

    e.preventDefault();
    try {
      const accessToken = await getAccessTokenSilently();

      let res = await fetch(
        `${backend_domain}${backend_port}/post/add-blog-post`,
        {
          method: "POST",
          body: JSON.stringify(jsondata),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (res.status === 200) {
        console.log("updated", props.postID);
      } else {
        console.log("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const isSelected = props.isSelected;

  if (isSelected) {
    if (!props.isActive) {
      // inactive, no posts yet
      if (props.posterID === props.currentUserID) {
        return (
          <>
            {!postActive && (
              <>
                <div className="text-black container mx-auto flex flex-col items-center justify-center p-3 space-y-8 md:p-10 md:px-24 xl:px-48">
                  <SVGComponent />
                  <h1 className="text-black font-bold text-3xl leading-none text-center">
                    Keep your fans up to date
                  </h1>
                  <h1 className="text-gray-500 text-xl leading-none text-center">
                    Help people coming to this envolvment stay up to date on
                    progress and updates
                  </h1>
                  <button
                    // onClick={handleSubmit}
                    onClick={() => setPostActive(true)}
                    className="text-2xl bg-black md:px-32 px-20 py-2 text-white border"
                  >
                    Create a post
                  </button>
                </div>
              </>
            )}
            {postActive && (
              <>
                <section className="p-6 dark:bg-gray-800 dark:text-gray-50">
                  <form onSubmit={handleSubmit}>
                    <fieldset className="mx-auto grid gap-6 p-6 rounded-md">
                      <div className="text-4xl text-bold col-span-full sm:col-span-2">
                        Create Post
                      </div>
                    </fieldset>

                    <fieldset className="mx-auto grid gap-6 px-6 pb-6 rounded-md shadow-sm dark:bg-gray-900">
                      <div className="px-6 grid grid-cols-6 gap-4 col-span-full lg:col-span-3 border rounded-xl border-gray-200">
                        <div className="col-span-full sm:col-span-6">
                          <label className="text-sm">Title (required)</label>
                          <input
                            type="text"
                            value={title}
                            placeholder="Title"
                            onChange={(e) => setTitle(e.target.value)}
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          />
                        </div>
                        <div className="col-span-full sm:col-span-6">
                          <label className="text-sm mb-2">
                            Description (required)
                          </label>
                          <textarea
                            value={description}
                            placeholder="Provide a detailed explanation of how your envolvement functions"
                            onChange={(e) => setDescription(e.target.value)}
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          ></textarea>
                        </div>
                        <FileUpload chooseImgUrl={chooseImgUrl} />
                        <div className="col-span-full sm:col-span-3">
                          <div className="text-center">
                            <button
                              type="submit"
                              className={`px-16 py-3 font-semibold rounded-xl bg-primary-300 `}
                            >
                              Publish
                            </button>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </section>
              </>
            )}
          </>
        );
      }

      if (props.posterID !== props.currentUserID) {
        return (
          <div className="text-black container mx-auto flex flex-col items-center justify-center p-3 space-y-8 md:p-10 md:px-24 xl:px-48">
            <SVGComponent />
            <h1 className="text-black font-bold text-3xl leading-none text-center">
              Nothing here yet
            </h1>
          </div>
        );
      }
    }
    if (props.isActive) {
      if (props.posterID !== props.currentUserID) {
        const arr = JSON.parse(props.existingPostJSON);
        console.log(arr);
        return (
          <div>
            <div>
              {arr.map((arr: Item) => {
                return (
                  <>
                    <fieldset className="mx-auto grid gap-6 md:px-12 px-6 pb-6 rounded-md shadow-sm dark:bg-gray-900">
                      <div className="px-6 grid grid-cols-6 gap-4 col-span-full lg:col-span-3 border rounded-xl border-gray-200">
                        <div className="col-span-full sm:col-span-6">
                          <div className="flex flex-row">
                            <div className="pr-2">
                              <img
                                src={posterImage}
                                className="h-16 w-16 border rounded-2xl border-4 border-black"
                              />
                            </div>
                            <div>
                              <label className="text-sm">
                                {arr.datePublished}
                              </label>
                              <div className="font-bold text-2xl">
                                {arr.title}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-span-full sm:col-span-6">
                          <div className="text-md">{arr.description}</div>
                        </div>

                        <div className="col-span-full sm:col-span-6">
                          <img
                            src={
                              arr.imgUrl !== ""
                                ? arr.imgUrl
                                : "https://upload.wikimedia.org/wikipedia/commons/b/b1/Missing-image-232x150.png"
                            }
                            alt="Example Envolvment"
                            className="mx-auto h-auto w-full max-w-xl"
                          />
                        </div>
                      </div>
                    </fieldset>
                    {/* <div>Title: {arr.title}</div>
                  <div>Description: {arr.description}</div>
                  <div>imgUrl: {arr.imgUrl}</div>
                  <div>datePublished: {arr.datePublished}</div> */}
                  </>
                );
              })}
            </div>
          </div>
        );
      }
      if (props.posterID === props.currentUserID) {
        const arr = JSON.parse(props.existingPostJSON);
        console.log(arr);
        return (
          <div>
            <div>
              {arr.map((arr: Item) => {
                return (
                  <>
                    <fieldset className="mx-auto grid gap-6 md:px-12 sm:px-6 pb-6 rounded-md shadow-sm dark:bg-gray-900">
                      <div className="px-6 grid grid-cols-6 gap-4 col-span-full lg:col-span-3 border rounded-xl border-gray-200">
                        <div className="col-span-full sm:col-span-6">
                          <div className="flex flex-row">
                            <div className="pr-2">
                              <img
                                src={posterImage}
                                className="h-16 w-16 border rounded-full border-1 border-black"
                              />
                            </div>
                            <div>
                              <label className="text-sm">
                                {arr.datePublished}
                              </label>
                              <div className="font-bold text-2xl">
                                {arr.title}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-span-full sm:col-span-6">
                          <div className="text-md">{arr.description}</div>
                        </div>

                        {arr.imgUrl !== "" ? (
                          <div className="col-span-full sm:col-span-6">
                            <img
                              src={arr.imgUrl !== "" ? arr.imgUrl : ""}
                              alt="Example Envolvment"
                              className="mx-auto h-auto w-full max-w-xl"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </fieldset>
                    {/* <div>Title: {arr.title}</div>
                  <div>Description: {arr.description}</div>
                  <div>imgUrl: {arr.imgUrl}</div>
                  <div>datePublished: {arr.datePublished}</div> */}
                  </>
                );
              })}
            </div>
            <div className="flex justify-center">
              <button
                // onClick={handleSubmit}
                onClick={() => setNewPost(true)}
                className="text-2xl bg-black md:px-32 px-20 py-2 text-white border"
              >
                Create a post
              </button>
            </div>
            {newPost && (
              <>
                <section className="p-6 dark:bg-gray-800 dark:text-gray-50">
                  <form onSubmit={handleSubmit}>
                    <fieldset className="mx-auto grid grid-cols-4 gap-6 p-6 rounded-md">
                      <div className="text-4xl text-bold col-span-full sm:col-span-2">
                        Create Post
                      </div>
                    </fieldset>

                    <fieldset className="grid px-6 pb-6 rounded-md shadow-sm dark:bg-gray-900">
                      <div className="px-6 grid grid-cols-6 gap-4 col-span-full lg:col-span-3 border rounded-xl border-gray-200">
                        <div className="col-span-full sm:col-span-6">
                          <label className="text-sm">Title (required)</label>
                          <input
                            type="text"
                            value={title}
                            placeholder="Title"
                            onChange={(e) => setTitle(e.target.value)}
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          />
                        </div>
                        <div className="col-span-full sm:col-span-6">
                          <label className="text-sm mb-2">
                            Description (required)
                          </label>
                          <textarea
                            value={description}
                            placeholder="Provide a detailed explanation of how your envolvement functions"
                            onChange={(e) => setDescription(e.target.value)}
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          ></textarea>
                        </div>
                        <FileUpload chooseImgUrl={chooseImgUrl} />
                        <div className="col-span-full sm:col-span-3">
                          <div className="text-center">
                            <button
                              type="submit"
                              className={`px-16 py-3 font-semibold rounded-xl bg-primary-300 `}
                            >
                              Publish
                            </button>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </section>
              </>
            )}
          </div>
        );
      }
    }
  }
}
