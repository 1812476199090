import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";

import FileUpload from "./FileUpload";

import "react-datepicker/dist/react-datepicker.css";

const backend_domain = process.env.REACT_APP_BACKEND_DOMAIN;
const backend_port = process.env.REACT_APP_BACKEND_PORT;

function CreateEnvolvement() {
  const { getAccessTokenSilently } = useAuth0();

  const { user, isAuthenticated } = useAuth0();
  const [title, setTitle] = useState("");
  const [previewText, setPreviewText] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState("");
  const [price, setPrice] = useState(0);
  const [free, setFree] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [unlimited, setUnlimited] = useState(false);
  const [category, setCategory] = useState("");
  const [endDate, setEndDate] = useState(null); // not new date since you cant set placeholder text
  const [none, setNone] = useState(false);

  const [submissions, setSubmissions] = useState(false);
  const [submissionDescription, setSubmissionDescription] = useState("");
  const [submissionDeadline, setSubmissionDeadline] = useState(null);

  const [comments, setComments] = useState(false);

  const [published, setPublished] = useState(false);
  const [publishedReleaseDate, setPublishedReleaseDate] = useState(null);

  const [imgUrl, setImgUrl] = useState("");

  const [message, setMessage] = useState("");
  const [username, setUsername] = useState("");

  const [postedID, setPostedID] = useState("");

  const [targetEnvolvmentDate, setTargetEnvolvmentDate] = useState(null);

  const [submissionContentPicture, setSubmissionContentPicture] =
    useState(false);
  const [submissionContentDescription, setSubmissionContentDescription] =
    useState(false);
  const [submissionContentUrl, setSubmissionContentUrl] = useState(false);

  const [disabled, setDisabled] = useState(true);

  var jsondata = {
    type: "BaseEnvolvement",
    isActive: false,
    userID: user?.sub,
    username: user?.nickname,
    userPicture: user?.picture,
    title: title,
    previewText: previewText,
    description: description,
    file: file,
    price: {
      price: price,
      free: free,
    },
    quantity: {
      quantity: quantity,
      unlimited: unlimited,
      currentEnvolves: 0,
    },
    category: category,
    endDate: {
      endDate: endDate,
      none: none,
    },
    submissions: {
      submissionsAllowed: submissions,
      submissionsActive: false,
      submissionsIDPair: " ",
      submissionDescription: submissionDescription,
      submissionDeadline: submissionDeadline,
      submissionContent: {
        picture: submissionContentPicture, // set con
        description: submissionContentDescription, // set con
        url: submissionContentUrl, // set con
      },
    },
    comments: comments,
    published: {
      published: published,
      publishedReleaseDate: publishedReleaseDate,
    },
    imgUrl: imgUrl,
    targetEnvolvmentDate: targetEnvolvmentDate,
    activityPostJSON: "",
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const accessToken = await getAccessTokenSilently();

      let res = await fetch(
        `${backend_domain}${backend_port}/post/new-base-envolvement`,
        {
          method: "POST",
          body: JSON.stringify(jsondata),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      let data = await res.json();

      if (res.status === 200) {
        setUsername(user.sub);
        setTitle("");
        setPreviewText("");
        setDescription("");
        setFile("");
        setPrice("");
        setFree("");
        setQuantity("");
        setUnlimited("");
        setCategory("");
        setEndDate("");
        setNone("");
        setSubmissions("");
        setComments("");
        setPublished("");
        setMessage("Envolvment posted succesfully. View it here");
        setPostedID(data.postedID);
      } else {
        setMessage("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const chooseImgUrl = (imgUrl) => {
    setImgUrl(imgUrl);
    console.log("BALLS!", imgUrl);
  };

  function handleChange(e) {
    console.log("Fruit Selected!!");
    setCategory(e.target.value);
  }

  function priceHandle(val) {
    setPrice(val);
  }

  function freeHandle(val) {
    setFree(val);
  }

  function quantityHandle(val) {
    setQuantity(val);
  }

  function unlimitedHandle(val) {
    setUnlimited(val);
  }

  useEffect(() => {
    checkSubmit();
  }, [unlimited]);

  useEffect(() => {
    checkSubmit();
    console.log(quantity);
  }, [quantity]);

  useEffect(() => {
    checkSubmit();
  }, [price]);

  useEffect(() => {
    console.log("ranm");
    if (endDate === null) setNone(false);
    if (endDate !== null) setNone(true);
  }, [endDate]);

  useEffect(() => {
    checkSubmit();
  }, [free]);

  function checkSubmit() {
    //else if(quantity != 0 && unlimited == true) setDisabled(true);
    //else if(price != 0 && free == true) setDisabled(true);

    // if(price == "" && free == false) setDisabled(true);
    // else if(price == 0 && free == false) setDisabled(true);
    // else if(quantity == "" && unlimited == false) setDisabled(true);
    // else if(quantity == 0 && unlimited == false) setDisabled(true);
    // else setDisabled(false)

    // if (price != 0) {
    //   if (free == true) setDisabled(true);
    //   else if (free == false) {
    //     if (quantity != 0) {
    //       if (unlimited == true) setDisabled(true);
    //       else if (unlimited == false) setDisabled(false);
    //     } else if (quantity == 0) {
    //       if (unlimited == false) setDisabled(true);
    //       else if (unlimited == true) setDisabled(false);
    //     }
    //   }
    // }

    // if (price == 0) {
    //   if (free == false) setDisabled(true);
    //   else if (free == true) {
    //     if (quantity != 0) {
    //       if (unlimited == true) setDisabled(true);
    //       else if (unlimited == false) setDisabled(false);
    //     } else if (quantity == 0) {
    //       if (unlimited == false) setDisabled(true);
    //       else if (unlimited == true) setDisabled(false);
    //     }
    //   }
    // }

    if (price < 0) return setDisabled(true);
    if (quantity < 0) return setDisabled(true);

    if (
      price !== null &&
      quantity !== null &&
      price !== "" &&
      quantity !== ""
    ) {
      if ((price != 0 || price !== null) && free == false) {
        if (quantity != 0 && unlimited == false) setDisabled(false);
      }

      if ((price != 0 || price !== null) && free == false) {
        if (quantity == 0 && unlimited == true) setDisabled(false);
      }

      if (price == 0 && free == true) {
        if (quantity != 0 && unlimited == false) setDisabled(false);
      }

      if (price == 0 && free == true) {
        if (quantity == 0 && unlimited == true) setDisabled(false);
      }
    } else setDisabled(true);
  }

  return (
    <>
      {isAuthenticated && (
        <div className="App">
          <section className="p-6 dark:bg-gray-800 dark:text-gray-50">
            <form onSubmit={handleSubmit}>
              <fieldset className="md:pl-96 mx-auto grid grid-cols-4 gap-6 p-6 rounded-md">
                <div className="text-4xl text-bold col-span-full sm:col-span-2">
                  Create Envolvment
                </div>
                <button
                  placeholder="Max Envolves"
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-200 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  Preview
                </button>
              </fieldset>

              <fieldset className="md:pl-96 mx-auto grid grid-cols-4 gap-6 px-6 pb-6 rounded-md shadow-sm dark:bg-gray-900">
                <div className="px-6 grid grid-cols-6 gap-4 col-span-full lg:col-span-3 border rounded-xl border-gray-200">
                  <div className="col-span-full sm:col-span-6">
                    <label for="title" className="text-sm">
                      Title
                    </label>
                    <input
                      required="required"
                      type="text"
                      value={title}
                      placeholder="Title"
                      onChange={(e) => setTitle(e.target.value)}
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  <div className="col-span-full">
                    <label for="Description" className="text-sm mb-2">
                      Preview Text (recommended)
                    </label>
                    <textarea
                      type="text"
                      value={previewText}
                      placeholder="Tell your viewers more about your envolvement"
                      onChange={(e) => setPreviewText(e.target.value)}
                      rows="2"
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    ></textarea>
                  </div>
                  <div className="col-span-full sm:col-span-3">
                    <label for="Description" className="text-sm mb-2">
                      Description (optional)
                    </label>
                    <textarea
                      type="text"
                      value={description}
                      placeholder="Provide a detailed explanation of how your envolvement functions"
                      onChange={(e) => setDescription(e.target.value)}
                      rows="12"
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    ></textarea>
                  </div>

                  <FileUpload chooseImgUrl={chooseImgUrl} />

                  {/* <div className="col-span-full sm:col-span-3">


              <label for="firstname" className="text-sm">Username</label>
                <input
                  type="text"
                  value={username}
                  placeholder="Username"
                  onChange={(e) => setUsername(e.target.value)}
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div> */}
                  <div className="col-span-full sm:col-span-5">
                    <label for="Tag1" className="text-sm">
                      Price
                    </label>
                    <fieldset className="w-full space-y-1 dark:text-gray-100">
                      <div className="flex">
                        <span className="flex items-center px-3 pointer-events-none sm:text-sm rounded-md bg-gray-300">
                          $
                        </span>
                        <input
                          type="number"
                          value={price}
                          onChange={(e) => priceHandle(e.target.value)}
                          name="price"
                          id="price"
                          placeholder="100"
                          className="flex flex-1 block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>
                    </fieldset>
                  </div>
                  <div classname="col-span-full sm:col-span-1">
                    <label for="Tag1" className="text-sm">
                      Free
                    </label>
                    <input
                      value={free}
                      onChange={(e) => freeHandle(e.target.checked)}
                      class="block w-8 h-8 accent-primary-300"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                    />
                  </div>

                  <div className="col-span-full sm:col-span-5">
                    <label for="Tag1" className="text-sm">
                      Quantity
                    </label>
                    <fieldset className="w-full space-y-1 dark:text-gray-100">
                      <div className="flex">
                        <input
                          type="number"
                          value={quantity}
                          onChange={(e) => quantityHandle(e.target.value)}
                          name="price"
                          id="price"
                          placeholder="Select"
                          className="flex flex-1 block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>
                    </fieldset>
                  </div>
                  <div classname="col-span-full sm:col-span-1">
                    <label for="Tag1" className="text-sm">
                      Unlimited
                    </label>
                    <input
                      value={unlimited}
                      onChange={(e) => unlimitedHandle(e.target.checked)}
                      class="block w-8 h-8 accent-primary-300"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                    />
                  </div>

                  <div className="col-span-full sm:col-span-3">
                    <label for="Tag1" className="text-sm">
                      Category
                    </label>
                    <select
                      value={category}
                      onChange={handleChange}
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option disabled selected>
                        Pick your favorite Simpson
                      </option>
                      <option value="homer">Homer</option>
                      <option value="marge">Marge</option>
                    </select>
                  </div>

                  <div className="col-span-full sm:col-span-2">
                    <label for="Tag1" className="text-sm">
                      End date &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </label>
                    <DatePicker
                      selected={endDate}
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      onChange={(endDate) => setEndDate(endDate)}
                      placeholderText={"Select"}
                    />
                  </div>

                  <div classname="col-span-full sm:col-span-2">
                    <label for="Tag1" className="text-sm">
                      None
                    </label>
                    <input
                      value={none}
                      onChange={(e) => setNone(e.target.checked)}
                      class="block w-8 h-8 accent-primary-300"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                    />
                  </div>

                  <div className="col-span-full sm:col-span-6">
                    <div className="text-center text-xl">
                      Envolvment Settings
                    </div>
                  </div>

                  <div className="col-span-full sm:col-span-full">
                    <div className="border border-gray-300 flex justify-between">
                      <div className="py-2 px-2">
                        Will this envolvment require user submissions?
                      </div>
                      <div>
                        <input
                          value={submissions}
                          onChange={(e) => setSubmissions(e.target.checked)}
                          class="mr-2 mt-[0.8rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary-500 checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                        />
                      </div>
                    </div>

                    <div
                      className={`${
                        submissions ? "border border-gray-300 px-8" : "hidden"
                      }`}
                    >
                      <div className="col-span-full sm:col-span-6">
                        <label for="Description" className="text-sm mb-2">
                          Description (recommended)
                        </label>
                        <textarea
                          type="text"
                          value={submissionDescription}
                          placeholder="Provide a detailed explanation of how your envolvement functions"
                          onChange={(e) =>
                            setSubmissionDescription(e.target.value)
                          }
                          rows="12"
                          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        ></textarea>

                        <div className="flex md:flex-row flex-col justify-between">
                          <div className="px-2 border border-gray-300 rounded-xl">
                            <label className="text-sm">
                              Allow Description Responses
                            </label>
                            <input
                              value={submissionContentDescription}
                              onChange={(e) =>
                                setSubmissionContentDescription(
                                  e.target.checked
                                )
                              }
                              class="mr-2 mt-[0.8rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary-500 checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                            />
                          </div>
                          <div className="px-2 border border-gray-300 rounded-xl">
                            <label className="text-sm">
                              Allow Picture Responses
                            </label>
                            <input
                              value={submissionContentPicture}
                              onChange={(e) =>
                                setSubmissionContentPicture(e.target.checked)
                              }
                              class="mr-2 mt-[0.8rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary-500 checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                            />
                          </div>
                          <div className="px-2 border border-gray-300 rounded-xl">
                            <label className="text-sm">
                              Allow URL Responses
                            </label>
                            <input
                              value={submissionContentUrl}
                              onChange={(e) =>
                                setSubmissionContentUrl(e.target.checked)
                              }
                              class="mr-2 mt-[0.8rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary-500 checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                            />
                          </div>
                        </div>

                        <label for="Tag1" className="text-sm">
                          Submission Deadline (optional)
                        </label>
                        <div className="text-xs pt-1 text-gray-400">
                          If you need your viewers to send their submissions by
                          a certain time, use this option to set a deadline.
                          They will still be able to add and edit their
                          submissions after the deadline
                        </div>

                        <div className="flex justify-center">
                          <DatePicker
                            selected={submissionDeadline}
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            onChange={(submissionDeadline) =>
                              setSubmissionDeadline(submissionDeadline)
                            }
                            placeholderText={"Select"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-full sm:col-span-full">
                    <div className="border border-gray-300 flex justify-between">
                      <div className="py-2 px-2">
                        Will this envolvment be published content?
                      </div>
                      <div>
                        <input
                          value={published}
                          onChange={(e) => setPublished(e.target.checked)}
                          class="mr-2 mt-[0.8rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary-500 checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                        />
                      </div>
                    </div>

                    <div
                      className={`${
                        published ? "border border-gray-300 px-8" : "hidden"
                      }`}
                    >
                      <div className="col-span-full sm:col-span-6">
                        <label for="Tag1" className="text-sm">
                          Estimated Release Date (optional)
                        </label>
                        <div className="text-xs pt-1 text-gray-400">
                          If your viewers or their submissions will be appearing
                          in published content, such as a video, they may want
                          to know when it will be releasing. Use this option to
                          display when your content is estimated to be released.
                        </div>
                        <div className="flex justify-center">
                          <DatePicker
                            selected={publishedReleaseDate}
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            onChange={(publishedReleaseDate) =>
                              setPublishedReleaseDate(publishedReleaseDate)
                            }
                            placeholderText={"Select"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-full sm:col-span-full border rounded-lg border-gray-300">
                    <div className="flex justify-between">
                      <div className="py-2 px-2">
                        Will this envolvment allow comments?
                      </div>
                      <div>
                        <input
                          value={comments}
                          onChange={(e) => setComments(e.target.checked)}
                          class="mr-2 mt-[0.8rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary-500 checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-span-full sm:col-span-6">
                    <label for="Tag1" className="text-sm">
                      Target Envolvment Date (optional)
                    </label>
                    <div className="text-xs pt-1 text-gray-400">
                      Envolvment often involve an activity that occurs after
                      viewers have purchased it. If your envolvment is based
                      around an event, use this option to let your buyers know
                      when to expect their envolvment to occur
                    </div>
                    <div className="flex justify-center">
                      <DatePicker
                        selected={targetEnvolvmentDate}
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        onChange={(targetEnvolvmentDate) =>
                          setTargetEnvolvmentDate(targetEnvolvmentDate)
                        }
                        placeholderText={"Select"}
                      />
                    </div>
                  </div>

                  <div className="border border-gray-400 col-span-full sm:col-span-6 shadow-md"></div>

                  <div className="col-span-full sm:col-span-3">
                    <div className="underline text-sm pt-2">
                      Read about your obligations as a seller
                    </div>
                  </div>

                  <div className="col-span-full sm:col-span-3">
                    <div className="text-center">
                      <button
                        type="submit"
                        className={`px-16 py-3 font-semibold rounded-xl bg-primary-300 ${
                          disabled && "bg-primary-800"
                        }`}
                        disabled={disabled}
                      >
                        Publish
                      </button>
                    </div>
                  </div>
                  <div className="col-span-full sm:col-span-6">
                    <Link to={`/app/envolvement/${postedID}`}>
                      <div
                        className={`${
                          message
                            ? "text-white font-bold bg-primary-500 border rounded-xl text-xl py-4 text-center"
                            : "hidden "
                        } && `}
                      >
                        {message ? <p>{message}</p> : null}
                      </div>
                    </Link>
                  </div>
                </div>
              </fieldset>
            </form>
          </section>
        </div>
      )}

      {!isAuthenticated && <div>log in to make an envolvement bozo</div>}
    </>
  );
}

export default CreateEnvolvement;
