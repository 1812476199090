import {useState} from 'react';
import { useNavigate } from 'react-router-dom';

function ProfileSearch() {

    const [message, setMessage] = useState('Initial value');
    const navigate = useNavigate();


  // 👇️ called every time input's value changes
  const handleChange = (event: any) => {
    setMessage(event.target.value);
  };

  function handleSubmit(e:any) {
    e.preventDefault();
    console.log(`Form submitted, ${message}`)
    navigate(`/profile/search/${message}`);
  }

  console.log(message);

  return (
    <div className="form-control grid h-screen place-items-center">
      <label className="label">
        <span className="label-text">Please input search username</span>

      </label>
      <form onSubmit={handleSubmit}>
      <input
         id="message"
         name="message"
         type="text"
         onChange={handleChange}
         value={message}
        placeholder="Type here"
        className="text-slate-400 input input-bordered w-full max-w-xs"
      />
        <button type="submit">Submit</button>
      </form>
      
      <label className="label">
      </label>
    </div>
  );
}

export default ProfileSearch;
