import { Route, Routes } from "react-router-dom";

import BlogPage from "./BlogPage";
import BlogLoader from "./BlogLoader";

export default function BlogHub() {
  return (
    <>
      <Routes>
        <Route index element={<BlogPage />} />
        <Route path="/:id" element={<BlogLoader />} />
      </Routes>
    </>
  );
}
