import demoImage from "../assets/webp/demoSoon.webp";

function ContactUs() {
  return (
    <div className="text-black px-16 md:px-40">
      <div className="items-start text-center font-bold text-3xl pb-6">
        Watch our demo
      </div>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/yDuAXjCEArw?si=du_PpQVW7xZaHQ5d"
        title="YouTube video player"
        className="w-full h-auto max-w-full border border-gray-200 rounded-lg dark:border-gray-700 aspect-video"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      ></iframe>
      {/* <img
        className="w-full h-auto max-w-full border border-gray-200 rounded-lg dark:border-gray-700 aspect-video"
        title="Demo"
        src={demoImage}
        /> */}
    </div>
  );
}

export default ContactUs;
