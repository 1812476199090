import React, { useState, ChangeEvent, FormEvent } from "react";

import exampleEnvolvement1 from "../assets/webp/exampleEnvolvement1.webp";
import exampleEnvolvement2 from "../assets/webp/exampleEnvolvement2.webp";
import exampleEnvolvement3 from "../assets/webp/exampleEnvolvement3.webp";
import exampleEnvolvement4 from "../assets/webp/exampleEnvolvement4.webp";


const Hero = () => (
  <section
    id="product"
    className="md:px-20 px-12 pt-8 flex md:flex-row flex-col md:space-x-32 justify-center items-center"
  >
    <div className="flex justify-center items-center flex-col">
      <h2 className={styles.heading2}>Engage Your Audience</h2>
      <p className={`${styles.paragraph} max-w-[470px]`}>
        Monetize your audience through unique interactive opportunities
      </p>
      <WaitlistButton />
    </div>

    <div className={layout.sectionImgReverse}>
      {/* <img
        src="https://cdn.discordapp.com/attachments/1054388299189456929/1093704948170117120/Frame_1_41.png"
        alt="hero"
        className="max-w-lg h-auto relative z-[5]"
      /> */}
      <ImageCarousel />
    </div>
  </section>
);

const WaitlistButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const [email, setEmail] = useState<string>("");

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Perform any necessary validation or submission logic here
    console.log("Submitted email:", email);
    // Reset the form
    setEmail("");
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  return (
    <p className={`text-[34px] pt-20`}>
      <button
        className="bg-primary-500 hover:bg-primary-700 text-white font-bold py-4 px-16 border border-primary-700 rounded"
        onClick={handleClick}
      >
        JOIN WAITLIST
      </button>
      <div
        className={`${
          isVisible ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-2"
        } transition-opacity duration-500 transform mt-4`}
      >
        <EmailForm />
      </div>
    </p>
  );
};

const ImageCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [
    `${exampleEnvolvement1}`,
    `${exampleEnvolvement2}`,
    `${exampleEnvolvement3}`,
    `${exampleEnvolvement4}`
    ];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <>
      <div className="max-w-lg h-40 md:h-auto relative z-[5]">
        <button
          className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-1 px-1 rounded-l transition duration-300"
          onClick={handlePrev}
          aria-label="Previous Image button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 24 24"
          >
            <path d="M19 21l-3.057 3L4 12l11.943-12L19 3l-9 9z" />
          </svg>{" "}
        </button>
        <img
          src={images[currentIndex]}
          alt="Carousel Image"
          className="w-full transition-opacity duration-300"
          // style={{ opacity: currentIndex === 0 || currentIndex === 1 ? 1 : 0  }}
        />
        <button
          className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-1 px-1 rounded-r transition duration-300"
          onClick={handleNext}
          aria-label="Next Image button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 24 24"
          >
            <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
          </svg>
        </button>
      </div>
    </>
  );
};

const EmailForm = () => {
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Perform any necessary validation or submission logic here
    console.log("Submitted email:", email);
    // Reset the form
    setEmail("");
    // Mark as submitted
    setIsSubmitted(true);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  if (isSubmitted) {
    return (
      <div className="font-poppins font-normal text-primary-300 text-[20px] leading-[30.8px]">
        Thank you!
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <label
        htmlFor="email"
        className="block mb-2 text-sm font-medium text-white"
      >
        Email Address:
      </label>
      <input
        type="email"
        id="email"
        value={email}
        onChange={handleChange}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="Enter your email"
        required
      />
      <button
        type="submit"
        className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
      >
        Submit
      </button>
    </form>
  );
};

const styles = {
  boxWidth: "xl:max-w-[1280px] w-full",

  heading2:
    "font-poppins font-semibold xs:text-[48px] text-[40px] text-white xs:leading-[76.8px] leading-[66.8px]",
  paragraph:
    "font-poppins font-normal text-primary-300 text-[20px] leading-[30.8px]",

  flexCenter: "flex justify-center items-center",
  flexStart: "flex justify-center items-start",

  paddingX: "sm:px-16 px-6",
  paddingY: "sm:py-16 py-6",
  padding: "sm:px-16 px-6 sm:py-12 py-4",

  marginX: "sm:mx-16 mx-6",
  marginY: "sm:my-16 my-6",
};

export const layout = {
  section: `flex md:flex-row flex-col ${styles.paddingY}`,
  sectionReverse: `flex md:flex-row flex-col-reverse ${styles.paddingY}`,

  sectionImgReverse: `flex ${styles.flexCenter}`,
  sectionImg: `flex-1 flex ${styles.flexCenter} md:ml-10 ml-0 md:mt-0 mt-10 relative`,

  sectionInfo: `flex-1 ${styles.flexStart} flex-col`,
};

export default Hero;
