import { Link } from "react-router-dom";

export default function EarlyAccess() {
  return (
    <div className="text-black container mx-auto flex flex-col items-center justify-center p-3 space-y-8 md:p-10 md:px-24 xl:px-48">
      <h1 className="md:text-black text-4xl font-bold leading-none text-center">
        Want to be the first one to use Envolvly?
      </h1>
      <h1 className="text-primary-400 text-5xl font-bold leading-none text-center">
        Become an Ambassador
      </h1>
      <h1 className="md:text-black text-white text-md font-bold leading-none text-center w-2/3">
        The Envolvly Ambassador Program is a personalized program that provides chosen creators with early-acces to new features, insider insights, and more.
      </h1>
      <Link to="/ambassador">
      <button className="text-2xl bg-primary-500 md:px-56 px-32 py-2 text-white border rounded-xl">
        APPLY
      </button>
      </Link>
    </div>
  );
}
