import blog0Image from "../assets/webp/blogImage0.webp";
import blog1Image from "../assets/webp/blogImage1.webp";
import blog2Image from "../assets/webp/blogImage2.webp";
import blog3Image from "../assets/webp/blogImage3.webp";

const blog = [
  {
    id: 0,
    img: `${blog0Image}`,
    title: "Introducing Blogs",
    date: "March 15th, 2023",
    description: "John Ireland speaks on Introduction of blogs to Envolvy",
    author: "John Ireland",
    role: "Co-Founder of Envolvly",
    markdown: `# Introducing Blogs

### Introducing Blogs
    
With the launch of our new website, we are introducing blog posts to keep our community updated while we make progress on Envolvly's development.
### Starting now, here are some things you can expect
    
* Announcements  
* Updates  
* Spotlights  
* Opportunities  
* More\n
    
All of our important information and updates will be posted to our main website, where you can find these blog posts. Expect more to come in the following months, and beyond!
    `,
  },
  {
    id: 1,
    img: `${blog1Image}`,
    title: "Envolvly Wins TiE University",
    date: "April 6th, 2023",
    description:
      "John Ireland speaks on our win at TiE University & whats to come",
    author: "John Ireland",
    role: "Co-Founder of Envolvly",
    markdown: `# Envolvly wins TiE University

### Victory at TiE University
    
To commence the true beginning of our platform, we are ecstatic to announce that Envolvly has taken runner-up position at the TiE University 2023 Oregon regional competition, representing Oregon State University.
    
This marks the first major milestone in our journey to grow our company—we look forward to completing our demo and beginning full-time outreach, which will be beginning in the months following.
    
### Moving Forward
    
Looking beyond our victory at TiE, the capital earnt from this competition will allow us to further scale and increase development production. We are making steady progress on finishing our new website, which will then allow us to begin full-time work on making evolvements function.
    
We are proud to have been involved in TiE, and even more thrilled to have emerged victorious. More updates to come.
    `,
  },
  {
    id: 2,
    img: `${blog2Image}`,
    title: "Launching our new website",
    date: "June 20th, 2023",
    description: "John Ireland speaks on new website and whats to come",
    author: "John Ireland",
    role: "Co-Founder of Envolvly",
    markdown: `# Launching our new website

### Our New Look

After months of hard work, dedication, and careful consideration, we are excited to unveil the redesigned look of our website. This comprehensive redesign represents our commitment to continuously enhance the user experience, which we hope will entail good fortune for the future.
    
Completely redesigned from our old look, we strived to build a responsive, effective, and enticing new design that will help attract new users. We wanted to ensure that every interaction on our website felt seamless, intuitive, and engaging. Through extensive user research, analysis, and feedback, we have carefully crafted a user-centric design that prioritizes simplicity, accessibility, and functionality.
    
### Refresh
    
The new design of our website reflects our brand's evolution and aligns with the modern digital landscape. We have refreshed the overall visual identity, introducing a clean and contemporary look. The color palette has been refined, incorporating subtle yet impactful changes that create a visually appealing and cohesive experience across all pages.
    
The new design not only improves the visual aesthetics but also enhances the presentation of our content. We have revamped our content sections, ensuring they are concise, informative, and visually engaging. Through the strategic use of imagery, typography, and multimedia elements, we aim to captivate our audience and effectively convey our message.
    
### Looking Forward
    
The redesign of our website represents a significant milestone for Envolvly. We are thrilled to present this fresh, modern look that enhances user experience, showcases our brand's evolution, and facilitates seamless engagement. We hope that our community will enjoy exploring the redesigned interface and find it both visually appealing and user-friendly. As we continue to grow and innovate, we remain committed to providing an exceptional online experience for our valued community. Stay tuned for more exciting updates and enhancements as we embark on this new chapter together!
    `,
  },
  {
    id: 3,
    img: `${blog3Image}`,
    title: "Envolvly and security",
    date: "July 30th, 2023",
    description: "James Smith speaks on Envolvly security",
    author: "James Smith",
    role: "Co-Founder of Envolvly",
    markdown: `# Envolvly and Security

### Ideology
    
At Envolvly, we believe in security over everything. When it came to developing, security has always been of the utmost importance, being at the forefront of all concerns involving Envolvly, everything else is secondary to security.
    
### Practices
    
Before any code goes live, it is tested extensively with in-house tests, code reviews, and third party detectors. Additionally, Envolvly only uses the most up to date technology, including software, hardware, and practices. 
    
### Partners
    
For our login, all login is handled with Auth0, a service by Okta, a software used to simplify logins. Auth0 is the industry standard and used by many companies, including Pfizer, Arduino, and Dicks.
    
As for handling user payments, that is handled by Stripe, the leading software for handling user payments safely & securely. Stripe customers include, but are not limited to, Amazon, Instacart, and Shopify.
    
### TL:DR
    
Envovly is made safe by current security practices, our partners, and our Ideology to keep security at the forefront of everything we do.`,
  },
];

export default blog;
