function Timeline(){
    return(
        <section className="dark:bg-gray-800 dark:text-gray-100">
	<div className="container max-w-5xl px-4 py-12 mx-auto">
		<div className="grid gap-4 mx-4 sm:grid-cols-12">
			<div className="col-span-12 sm:col-span-3">
				<div className="text-center sm:text-left mb-14 before:block before:w-24 before:h-3 before:mb-5 before:rounded-md before:mx-auto sm:before:mx-0 before:dark:bg-primary-400">
					<h3 className="text-3xl font-semibold">Tentative Roadmap</h3>
					<span className="text-sm font-bold tracking-wider uppercase dark:text-gray-400">Envolvly Roadmap</span>
				</div>
			</div>
			<div className="relative col-span-12 px-4 space-y-6 sm:col-span-9">
				<div className="col-span-12 space-y-12 relative px-4 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:dark:bg-gray-700">
					<div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:dark:bg-primary-400">
						<h3 className="text-xl font-semibold tracking-wide">Launch</h3>
						<time className="text-xs tracking-wide uppercase dark:text-gray-400">~2023</time>
						<p className="mt-3">When Envolvly will finally go public - for everyone. Expected launch early 2023, with support for everyone, everywhere, on any platform.</p>
					</div>
					<div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:dark:bg-primary-400">
						<h3 className="text-xl font-semibold tracking-wide">Beta Launch</h3>
						<time className="text-xs tracking-wide uppercase dark:text-gray-400">September 2022</time>
						<p className="mt-3">Bigger test, official beta. Sign-ups for creators & audiences will be available. Will provide data, funding, stats on Envolvly.</p>
					</div>
					<div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:dark:bg-primary-400">
						<h3 className="text-xl font-semibold tracking-wide">Soft Launch</h3>
						<time className="text-xs tracking-wide uppercase dark:text-gray-400">Summer 2022</time>
						<p className="mt-3">Small test launch to collect data, stress-test the applicaiton, and have the first public showing of Envolvly. Will work with large creators & audiences.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
    )
}

export default Timeline;