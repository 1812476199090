import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Route, Routes } from "react-router-dom";
import { SkeletonTheme } from "react-loading-skeleton";
// libraries

// import styles from "./styles";
// styles

import Home from "./components/Home";
import LoadingAnimation from "./components/miscComponents/LoadingAnimation";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import FeaturesPage from "./components/featuresComponents/FeaturesPage";
import NotFoundPage from "./components/miscComponents/NotFoundPage";
import AboutUsPage from "./components/aboutUsComponents/AboutUsPage";
import ContactPage from "./components/contactComponents/ContactPage";
import Envolvement from "./components/envolvementComponents/Envolvement.jsx";

import AmbassadorPage from "./components/ambassadorComponents/Ambassador";
import TermsAndPrivacyPage from "./components/miscComponents/TermsAndPrivacy";

import ProfilePage from "./components/profileComponents/ProfilePage";
import BlogHub from "./components/blogComponents/BlogHub";
import AppHub from "./components/appComponents/AppHub";
import PaymentPage from "./components/paymentComponents/PaymentPage";

// components

// TODO: envolvement uploads, add sitemap, OPTIMIZE SEO!,
// TODO:lambda functions, prod servers vs dev

const BACKEND_DOMAIN: string =
  process.env.REACT_APP_BACKEND_DOMAIN ?? "ENV BACKEND_DOMAIN DEFAULT";
const BACKEND_PORT: string =
  process.env.REACT_APP_BACKEND_PORT ?? "ENV BACKEND_PORT DEFAULT";

function App() {
  // const [APIResponse, setAPIResponse] = React.useState("");
  const { isLoading, error } = useAuth0(); //conditial rendering from auth0

  // React.useEffect(() => { code to test backend calling locally
  //   fetch(`http://${BACKEND_DOMAIN}${BACKEND_PORT}/testAPI`)
  //     .then((res) => res.text())
  //     .then((res) => setAPIResponse(res));
  // }, []);

  return (
    <>
      <Navbar />
      {error && <p>Authentication Error</p>}
      {!error && !isLoading && (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms-and-privacy" element={<TermsAndPrivacyPage />} />
          <Route path="/features" element={<FeaturesPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/contact-us" element={<ContactPage />} />
          <Route path="/envolvement" element={<Envolvement />} />
          <Route path="/ambassador" element={<AmbassadorPage />} />
          <Route path="/blog/*" element={<BlogHub />} />
          <Route path="/app/*" element={<AppHub />} />
          <Route path="/payment/*" element={<PaymentPage />} />
          <Route path="/profile/*" element={<ProfilePage />} />
          <Route path="*" element={<NotFoundPage />} /> {/*page not found */}
        </Routes>
      )}
      <Footer />
      {/* <p className="App-intro">{APIResponse}</p>{" "} */}
      {/* dev stuff, api connection.*/}
    </>
  );
}

export default App;
