export default function Ambassador() {
  return (
    <>
      <main className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-envolvlyGray">
        <div className="flex justify-between px-4 mx-auto max-w-screen-xl ">
          <article className="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-invert text-gray-400">
            <header className="not-format">
              <h1 className="text-3xl font-extrabold leading-tight lg:mb-6 lg:text-4xl text-white">
                Join the Envolvly Ambassador Program
              </h1>
            </header>
            The Envolvly Ambassador Program is a personalized program that
            provides chosen creators with early-access to new features, insider
            insights, and more. We want to build a community of like-minded
            content creators looking to grow and expand their viewer engagement.
            <br></br>
            <br></br>
            Enrolling in our Ambassador Program to recevie special benefits
            coming soon!
            <form className="border rounded-xl border-gray-200">
              <div className="bg-white px-6 grid grid-cols-6 gap-4 col-span-full lg:col-span-3 ">
                <div className="col-span-full sm:col-span-6">
                  <label className="text-black text-sm">Name</label>
                  <input className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                </div>
              </div>

              <div className="bg-white px-6 grid grid-cols-6 gap-4 col-span-full lg:col-span-3">
                <div className="col-span-full sm:col-span-6">
                  <label className="text-black text-sm">
                    Handle (channel name, account name, etc.)
                  </label>
                  <input className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                </div>
              </div>

              <div className="bg-white px-6 grid grid-cols-6 gap-4 col-span-full lg:col-span-3">
                <div className="col-span-full sm:col-span-6">
                  <label className="text-black text-xl font-bold">
                    Primary Platform
                  </label>
                  <div>
                    <input type="radio" id="" name="drone" value="huey" />
                    <label className="text-black px-2">YouTube</label>
                  </div>

                  <div>
                    <input type="radio" id="dewey" name="drone" value="dewey" />
                    <label className="text-black px-2">Twitch</label>
                  </div>

                  <div>
                    <input type="radio" id="louie" name="drone" value="louie" />
                    <label className="text-black px-2">Tik Tok</label>
                  </div>

                  <div>
                    <input type="radio" id="louie" name="drone" value="louie" />
                    <label className="text-black px-2">Instagram</label>
                  </div>

                  <div>
                    <input type="radio" id="louie" name="drone" value="louie" />
                    <label className="text-black px-2">
                      Other:
                      <span className="px-2">
                        <input className="border border-black rounded-md" />
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="bg-white px-6 grid grid-cols-6 gap-4 col-span-full lg:col-span-3">
                <div className="col-span-full sm:col-span-6">
                  <label className="text-black text-xl font-bold">
                    Content Types
                  </label>

                  <div>
                    <input type="checkbox" id="scales" name="scales" />
                    <label className="text-black px-2">Autos & Vehicles</label>
                  </div>

                  <div>
                    <input type="checkbox" id="horns" name="horns" />
                    <label className="text-black px-2">Comedy</label>
                  </div>

                  <div>
                    <input type="checkbox" id="horns" name="horns" />
                    <label className="text-black px-2">Education</label>
                  </div>

                  <div>
                    <input type="checkbox" id="horns" name="horns" />
                    <label className="text-black px-2">Entertainment</label>
                  </div>

                  <div>
                    <input type="checkbox" id="horns" name="horns" />
                    <label className="text-black px-2">Film & Animation</label>
                  </div>

                  <div>
                    <input type="checkbox" id="horns" name="horns" />
                    <label className="text-black px-2">Gaming</label>
                  </div>

                  <div>
                    <input type="checkbox" id="horns" name="horns" />
                    <label className="text-black px-2">
                      How To / Tutorials
                    </label>
                  </div>

                  <div>
                    <input type="checkbox" id="horns" name="horns" />
                    <label className="text-black px-2">Music</label>
                  </div>

                  <div>
                    <input type="checkbox" id="horns" name="horns" />
                    <label className="text-black px-2">News & Politcs</label>
                  </div>

                  <div>
                    <input type="checkbox" id="horns" name="horns" />
                    <label className="text-black px-2">People & Blogs</label>
                  </div>

                  <div>
                    <input type="checkbox" id="horns" name="horns" />
                    <label className="text-black px-2">Pets & Animals</label>
                  </div>

                  <div>
                    <input type="checkbox" id="horns" name="horns" />
                    <label className="text-black px-2">
                      Science & Technology
                    </label>
                  </div>

                  <div>
                    <input type="checkbox" id="horns" name="horns" />
                    <label className="text-black px-2">Sports</label>
                  </div>

                  <div>
                    <input type="checkbox" id="horns" name="horns" />
                    <label className="text-black px-2">Travel & Events</label>
                  </div>

                  <div>
                    <input type="radio" id="louie" name="drone" value="louie" />
                    <label className="text-black px-2">
                      Other:
                      <span className="px-2">
                        <input className="border border-black rounded-md" />
                      </span>
                    </label>
                  </div>

                  <div className="col-span-full sm:col-span-6">
                    <label className="text-black text-xl font-bold">
                      Provide a link to your platform:
                    </label>
                    <input className="block border border-black rounded-md" />
                  </div>
                  <div className="flex">
                    <button className="sm:ml-auto justify-items-end text-md bg-primary-500 px-16 py-2 text-white border rounded-xl">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </article>
        </div>
      </main>
    </>
  );
}
